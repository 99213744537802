export class IntakeSessionConstants {
  static INTAKE_SESSION_DATA = 'intakeSessionData';
  static FDA_PAST_INDICATOR = 'fdaPast';
  static IS_FDA_ESTIMATE_ON = 'isEstimateFDAOn';
  static FDA_INDICATOR_FLAG = 'fdaIndicatorFlag';
  static FDA_UNKNOWN = 'FDA';
  static NI_OWCS_CONTENT = 'NewIntakeOwcsContent';
  static FIRST_DAY_ABSENT = 'firstDayAbsent';
  static SYSTEM_DATE = 'systemDate';
  static LAST_WORK_DAY = 'lastWorkDay';
  static COMPANY_VALUE = 'companyPermValue';
  static INTAKE_PROGRESS_BAR = 'intakeProgressBar';
  static SUBMATERNITY_RADIO_SELECTION = 'subMaternityRadioSelection';
  static MATERNITY_REASON = 'maternityReason';
  static PREGNANCY = 'Pregnancy';
  static INTAKE_TYPE_MATERNITY = 'maternity';
  static DEFAULT_MANDATED_OT_NONWORKDAY_RESPONSE = 'defaultMandatedOTNonWorkdayResponse';
  static MATERNITY_ANNOUNCEMENT_SECTION = 'maternityAnnouncementSection';
  static INTAKE_FIRST_DAY_OF_ABSENCE = 'intakeFirstDayOfAbsence';
  static IS_MARKED_AS_COMPLETE = 'isMarkAsCompleteTrue';
  static COVERED_REASON_LISTS = 'CoveredReasonLists';
  static CHECKBOX_STATE = 'CheckboxState';
  static LOA_INDICATOR = 'LOAIndicator';
  static MANDATORY_OVERTIME_FLAG = 'MandatoryOverTimeFlag';
  static REDUCED_SCHEDULE_PREFILL = 'reducedSchedulePrefill';
  static NEW_FDA_INDICATOR = 'NewFDAIndicator';
  static NEW_INTAKE_WORK_SCHEDULE_INDICATOR = 'NewIntakeWorkScheduleIndicator'
  static CONTACT_INFO = 'contactInfo';
  static STATE_LIST = 'stateList';
  static EDITED_CONTACT_INFO = 'contactInfoEdited';
  static WORK_SCHEDULE = 'workScheduleList';
  static STD_ELIGIBILITY = 'stdEligibility';
  static HEALTH_PROVIDER_DETAILS = 'healthProviderDetails';
  static INSURANCE_CARRIER_LIST = 'insuranceCarrierList';
  static NEW_HEALTHCARE_PROVIDER = 'newHealthCareProvider';
  static INTAKE_TOGGLES = 'intakeToggles';
  static CUSTOMER_SPECIFIC_TOGGLES = 'customerSpecificToggles';
  static JSON_CONTACT_INFO = 'NIContactInfoJSONClob';
  static NAVIGATE_WITHOUT_ADD_PROVIDER = 'navigateWithoutAddProvider';
  static CHECK_ZIP_CODE = 'checkZipCode';
  static SECONDARY_RELATIONSHIP_RES = 'secondaryRelationshipRes';
  static SECONDARY_RELATIONSHIP_LIST = 'secondaryRelationshipList';
  static BIRTHING_QUES_NO_RESPONSE = 'birthingQuesNoResponse';
  static PRIMARY_RELATIONSHIP = 'primaryRelationship';
  static SELECTED_PRIMARY_RELATIONSHIP = 'selectedPrimaryRelationship';
  static SELECTED_SECONDARY_RELATIONSHIP = 'selectedSecondaryRelationship';
  static SECONDARY_RELATIONSHIP_DROPDOWN_VALUE = 'secondaryRelationshipDropdownValue'
  static SELECTED_BIRTHING_PARENT_QUES = 'selectedBirthingParentQues';
  static PRESELECTED_BONDING_FLAG = 'bondingPreselected';
  static PRESELECTED_WORK_SCHEDULE = 'preSelectedWorkSchedule';
  static SECONDARY_RELATIONSHIP_FLAG = 'secondaryRelationshipFlag';
  static SHOW_SECONDARY_RELATIONSHIP = 'showSecondaryRelationship';
  static PRESELECTED_CONTACT_INFO = 'preselectedContactInfo';
  static SHOW_SECONDARY_DROPDOWN_DEFAULT_VALUES = 'showSecondaryDropdownDefaultValues';
  static STAT_FLAG = 'stat_flag';
  static FETCH_LOOK_UP = 'fetch_look_up';
  static STATES_CODE_LIST = 'statesCodeList';
  static LOCAL_REGULATION_DATA = 'localRegulationData';
  static START_MATERNITY_CLAIM_CLICK = 'onClickStartMaternityClaim';
  static BONDING_ANNOUNCEMENT_SECTION = 'bondingAnnouncementSection';
  static ADDED_PROVIDER ='addedProvider';
  static PD_ANNOUNCEMENT_BOOLEAN= 'showPDNotification';
  static IS_EDIT_WORK_SCHEDULE = 'isEditWorkSchedule';
  static WORK_SCHEDULE_PAGE = 'workSchedulePage';
  static IS_ROTATING_SCHEDULE = 'isRotatingSchedule';
  static IS_WEEKLY_SCHEDULE = 'isWeeklySchedule';
  static IS_MULTIWEEK_SCHEDULE = 'isMultiweekSchedule';
  static PD_APPLICABLE = 'showPDQuestions'
  static ROTATION_FIRST_DATE_DURING_ABSENCE = 'rotationFirstDateDuringAbsence';
  static CHANGE_FDA_WORKSCHEDULE = 'changeFDAorWorkSchedule';
  static WORK_SCHEDULE_VALIDATION = 'workScheduleValidation';
  static REASONOFLATEREPORTING = 'reasonOfLateReporting';
  static REASONOFLATEREPORTINGMATERNITY = 'LateReportedAbsenceReason';
  static STATE_DISABILITY = 'stateDisability';
  static EMPLOYEE_STATUTORY_STATE = 'EmployeeStatutoryState'
  /**Intake JSON */
  static STEP_ONE_JSON_INFO = 'stepOneJsonInfo';
  static RELEASE_RECORD_JSON_CLOB = 'releaseRecordJSONClob';
  static RELEASE_RECORD_JSON_CLOB_LOA = 'releaseRecordForLOA';
  static HEALTHCARE_REGISTERD_JSON = 'healthacareRegisterdJson';
  static STEP_TWO_JSON_INFO = 'steptwoJsonInfo';
  static STEP_THREE_ABSENCE_INFO = 'stepthreeJsonInfo';
  static STEP_FOUR_JSON_INFO = 'stepFourJsonInfo';
  static STEP_FIVE_JSON_INFO = 'stepFiveJsonInfo';
  static STEP_SIX_JSON_INFO = 'stepSixJsonInfo';
 /**Intake Formcontrol JSON for back navigation */
  static CONTACT_INFO_RAW_VALUES = 'contactInfoRawValues';
  static ABOUT_ABSENCE_RAW_VALUES = 'AbsenceInfoRawValues';
  static ABOUT_ABSENCE_FUTURE_RAW_VALUES = 'AbsenceFutureRawValues';
  static EMPLOYEE_DETAILS_RAW_VALUES = 'employeeDetailsRawValues'
  static ABOUT_DELIVERY_RAW_VALUES = 'AbsenceDeliveryRawValues';
  static ABOUT_DELIVERY_RECENTlY_RAW_VALUES = 'AbsenceDeliveryRecentlyRawValues';
  static FAX_NUMBER_RAW_VALUES = 'faxNumberRawValues';
  static HEALTHCARE_WITH_FAX = 'HealthcareWithFaxList'
  static NPI_API_FAILED = 'npiApiFailed';
/**Intake Formcontrol JSON for FDA Page */
  static INTAKE_FDA_RAW_VALUES = 'intakeFDARawValues';
  static CLAIM_SUBMIT_FDA_DETAILS = 'claimSubmitDetails_FDA';
  static CLAIM_SUBMIT_CONTACT_INFO = 'claimSubmitDetails_ContactInfo';
  static CLAIM_SUBMIT_CONTACT_DETAILS = 'claimSubmitDetails_ContactDetails';
  static CLAIM_SUBMIT_PROVIDER = 'claimSubmitDetails_Provider';
  static CLAIM_SUBMIT_MED_AUTH = 'claimSubmitDetails_MedAuth';
  static CLAIM_SUBMIT_ABSENCE = 'claimSubmitDetails_Absence';
  static CLAIM_SUBMIT_DELIVERY = 'claimSubmitDetails_Delivery';
  static CLAIM_SUBMIT_EMPLOYMENT = 'claimSubmitDetails_Employment';
  static CLAIM_SUBMIT_NOTIFICATION_PREFERENCE = 'claimSubmitDetails_NotificationPref';
  static INTAKE_DATE = 'intakeDate'
  static INTAKE_REASON = 'intakeReason';
  static INTAKE_SUB_REASON = 'intakeSubReason'
  static INTAKE_ABSENT ='absentVal'
  static HEALTHCARE_SUMMARY_RAW_VALUES = 'healthcareSumamryRawValues';
  static CURRENT_DATE_INDICATOR = 'currentDateIndicator'
  static IE_OWCS_CONTENT = 'IntakeExtensionOwcsContent';
  static ABOUT_ZIP_CODE = 'AboutZipCode'
  static REASON_CONTINUITY_RESPONSE = 'reasonContinuityResponse';
  static REASON_CONTINUITY_PAYLOAD = 'reasonContinuityPreviousPayload';
  static CLAIM_SUBMIT_RESPONSE = 'claimSubmitResponse'
  static FETCH_COMPANY_PARM ='fetchCompanyParm';
  static SUBMISSION_CONFIRMATION ='submissionConfirmation'
  static PD_CLASSIFICATIION = 'pdClassification';
  static MATERNITY_INTAKE_ABANDON = 'maternityIntakeAbandon';
  static MATERNITY_INTAKE_SUCCESS = 'maternityIntakeSuccess';
  static BONDING_FLOW_APPLICABLE = 'bondingFlowApplicable';
  static RELATIONSHIP_LIST = 'relationshipList';
  static DELIVERY_REASON = 'deliveryReason';
  static RETURN_REASON ='returnReason';
  static DELIVERY_REASON_FUTURE = 'deliveryReasonFuture';
  static RETURN_REASON_FUTURE ='returnReasonFuture'
  static DEFAULT_MANDATED_OT_NONWORKDAY_RESPONSE_FROM_MODAL = 'defaultMandatedOTNonWorkdayResponseFromModal';
  static LEAVE_CONTINUITY_RADIO_OPTION =   'LeaveContinuityRadioOption';
  static SPOUSE_SHARED_LEAVE_FLAG = 'spouseSharedLeaveFlag';
  static FDA_LATE_REPORTING = 'fdaLateReporting';
}

export class BondingIntakeSessionConstants {
  static BONDING_INTAKE_SESSION_DATA = 'bondingIntakeSessionData';
  static PAST_BONDING_LEAVES = 'pastBondingLeaves';
  static INTAKE_TYPE_BONDING = 'bonding';
  static PAST_MATERNITY_LEAVES = 'pastMaternityLeaves';
  static SELECTED_LEAVE_RELATIONSHIP = 'selectedLeaveRelationship';
  static SELECTED_LEAVE_RELATIONSHIP_TEXT = 'selectedLeaveRelationshipText';
  static GAP_IN_WORKDAY = 'gapInWorkDay';
  static SELECTED_VAL = 'selectedVal';
  static PAST_LEAVE_RELATIONSHIP_VALUE = 'pastLeaveRelationshipValue';
  static BONDING_FLOW = 'bondingFlow';
  static LAST_DAY_WORK = 'lastDayWork';
  static ABSENCE_EXTENSION_DATA = 'absenceExtensionData';
  static BIRTHING_QUES_RESPONSE = 'birthingQuesResponse';
  static BIRTHING_PARENT_ANSWERED_EXISTING = 'birthingQuesResponseExisting';
  static EXTENSION_BONDING = 'extensionBonding';
  static TRANSITION_TO_BONDING = 'transitionToBonding';
  static SELECTED_BONDING_LEAVE = 'selectedBondingLeave';
  static SELECTED_CONTINUITY = 'selectedContinuity';
  static INTAKE_EXISTING_MATERNITY_BONDING = 'intakeExistingMaternityBonding';
  static SELECTED_LEAVE_DETAILS = 'selectedLeaveDetails';
  static SELECTED_BONDING_TILE = 'selectedTile';
  static EXISTING_BONDING_JSON = 'existingBondingJson';
  static SELECTED_RADIO_CONTINUITY = 'selectedRadioContinuity';
  static SELECTED_BIRTHING_PARENT_QUES = 'selectedBirthingParentQues';
  static RADIO_TILE_OPTIONS = 'radiotileOptions';
  static PRESELECTED_RELATIONSHIP_VALUES = 'preselectedRelationshipValues';
  static SHOW_ADDITIONAL_INFO_BONDING = 'showAdditionalInfoBonding';
  static INTERMITTENT_FLAG = 'intermittentFlag';
  static CONTINUITY_YES = 'continuityYes';
  static CONTINUITY_NO = 'continuityNo';
  static MANDATORY_OVERTIME = 'mandatoryOvertime';
  static MANDATORY_OT_REDUCE_SCHEDULE = 'manOTReducedSched';
  static OVERTIME_DATE_ARRAY = 'overtimeDateArray';
  static MATERNITY_RADIO_TILE_OPTIONS = 'maternityRadioTileOptions';
  static SELECTED_MATERNITY_TILE = 'selectedMaternityTile';
  static MODAL_RADIO_OPTIONS = 'modalRadioOptions';
  static LATE_REPORTING_RESPONSE = 'lateReportingResponse';
  static LATE_REPORTING_END_DATE = 'lateReportingEndDate';
  static DEFAULT_MANDATED_OT_NONWORKDAY_RESPONSE = 'defaultMandatedOTNonWorkdayResponse';
  static CONTINUITY = 'continuity';
  static FLIGHT_CREW = 'flightCrew';
  static RS_MANDATORY_RADIO = 'rsMandatoryRadio';
  static RS_DEFAULT_MANDATORYOT_RESPONSE = 'rsDefaultMandatoryOTResponse';
  static RS_LEAVE_REQUESTED_RADIO = 'rsLeaveRequestedRadio';
  static REDUCED_SCHEDULE_PREFIL = 'reducedSchedulePrefill';
  static EXTENSION_PROCESS_OR_NEW_LOA = 'extensionProcessOrNewLOA';
  static FDA_UPDATE = 'FDAUpdate';
  static DEFAULT_DAY_TYPE = 'defaultDayType'
  static DEFAULT_MANDATED_OT_NONWORKDAY_RESPONSE_FROM_MODAL = 'defaultMandatedOTNonWorkdayResponseFromModal';
  static PRIMARY_RELATIONSHIP_ADOPT_OPT_ID='6';
  static PRIMARY_RELATIONSHIP_FOSTER_OPT_ID='7';
  static NAVIGATE_ON_MATERNITY_FLOW = 'navigateOnMaternityFlow';
  static RETURN_TO_WORK_PAST_INDICATOR='returnToworkPast';
  static FIND_RS_WORK_HEADER_TITLE='workHourHeader';
  static FIND_RS_MISS_HEADER_TITLE='misfHourHeader';
  static FULL_BONDING_FLOW = 'fullBondingFlow';
  static SELECTED_LEAVE_CONTINUITY = 'selectedLeaveContinuity';
  static BONDING_INTAKE_ABANDON = 'bondingIntakeAbandon';
}
