import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppLeaveIntakeConstants } from '@app/cxr/leave-claim-intake-flow/leave-intake.constants';
import { AppLeaveConstants } from '@app/cxr/leave-details-flow/leave-details.constants';
import { TAKING_LEAVE_BONDING_IDS } from '@app/cxr/modules/intake/components/bonding/shared/owcs-content-ids';
import { IHukRadioYesNoOptions, INTAKE_OWCS_IDS } from '@app/cxr/modules/intake/shared/JSON/intake-owcs.constants';
import { INTAKE_ROUTES } from '@app/cxr/modules/intake/shared/constants/Intake-routing.constants';
import { INTAKE_ICONS } from '@app/cxr/modules/intake/shared/constants/intake-icon.constants';
import { IntakeLabelConstants } from '@app/cxr/modules/intake/shared/constants/intake-label.constants';
import {
  BondingIntakeSessionConstants,
  IntakeSessionConstants
} from '@app/cxr/modules/intake/shared/constants/intakeSession.constants';
import { ISelectedLeaveDetails } from '@app/cxr/modules/intake/shared/models/selected-leave-details.model';
import {
  IEmpWorkScheduleResponse,
  IPrefillDetails,
  IWorkSchedulelist
} from '@app/cxr/modules/intake/shared/models/work-schedule-details.model';
import { ContactStateService } from '@app/cxr/modules/intake/shared/services/contact-state.service';
import { IntakeUtilitiesService } from '@app/cxr/modules/intake/shared/services/intake-utilities.service';
import { PrefillLogicService } from '@app/cxr/modules/intake/shared/services/prefill-logic.service';
import { ModalService } from '@app/shared/services/modal.service';
import { OwcsUtilityService } from '@app/shared/services/owcs-utility.service';
import { SessionConstants } from '@shared/constants/session.constants';
import { CustomEventService } from '@shared/services/tealium/custom-event.service';
import { AppLabelConstants } from '../../../../shared/constants/appLabel.constants';
import { StorageManagementService } from '../../../../shared/services/storage-management.service';

/**
 * Work Schedule display Component
 */
@Component({
  selector: 'app-work-schedule-list',
  templateUrl: 'work-schedule-list.component.html',
  styleUrls: ['work-schedule-list.component.scss']
})
export class WorkScheduleListComponent implements OnInit, AfterContentInit {

  @Input() regularWorkSchedule: IEmpWorkScheduleResponse;
  @Input() isWorkScheduleConfirmed: boolean;
  @Output() workScheduleDetails: EventEmitter<IWorkSchedulelist> = new EventEmitter<IWorkSchedulelist>();
  @Output() onContinue: EventEmitter<boolean> = new EventEmitter<boolean>();

  public appConst = {...AppLabelConstants, ...AppLeaveConstants, ...AppLeaveIntakeConstants};
  public regularWorkScheduleDisplay: IEmpWorkScheduleResponse;
  public dayMapping = [{ 1: 'Sunday', 2: 'Monday', 3: 'Tuesday', 4: 'Wednesday', 5: 'Thursday', 6: 'Friday', 7: 'Saturday' }];
  public appConstant = AppLeaveIntakeConstants;
  public appLabelConstant = AppLabelConstants;
  public regularWorkHours = { hours: 0, minutes: 0 };
  public radioYesChecked: boolean = false;
  workScheduleType: string;
  owcsContentMap: Map<string, string> = new Map();
  intakeExtensionOwcsContentMap: Map<string, string> = new Map();
  workScheduleTypeLabel: string;
  scheduleQuestionLabel: string;
  totalWorkMinutes: number = 0;
  intakeOWCSIds = INTAKE_OWCS_IDS;
  totalWorkHoursMins: string;
  intakeIcons = INTAKE_ICONS;
  rotatingScheduleQues: string;
  dateIsInvalid: string = IntakeLabelConstants.DATE_IS_INVALID;
  invalidDate: boolean = false;
  isRotatingSchedule: boolean;
  workScheduleDetail: IWorkSchedulelist;
  workScheduleListForm: FormGroup = new FormGroup({});
  displayRotationQuestion: boolean;
  bondingSessionConst = BondingIntakeSessionConstants;
  fdaDate: string = '';
  rotationFirstDateWhen_Yes: string | boolean;
  dateIntake: string | boolean;
  workScheduleSet: boolean = false;
  displayMandatedOTQuestion: boolean = false;
  mandatedOTTitle: string;
  confirmWorkScheduleRadioOptions: IHukRadioYesNoOptions[];
  mandatedOvertimeRadioOptions: IHukRadioYesNoOptions[];
  mandateOvertimeFDALabel: string = '';
  mandateOTModalContent: string = '';
  continueBtnDisabled: boolean = true;
  checkChangeFirstDayAbsent: boolean = false;
  checkChangeWorkSchedule: boolean = false;
  displayMandatedOT: boolean = false;
  isFDAANonWorkDay: boolean = false;
  mandatoryOTAnswer: any;
  workScheduleFormData: IPrefillDetails;
  isLOAEnabled: boolean = false;
  mandatedOTNonWorkdayResponse: string;
  intakeType: string;
  defaultMandatedOTNonWorkdayResponse: string;
  disableButton: boolean = false;
  isSpinnerDisabled: string = 'false';
  isDisabled: boolean = false;
  constructor(private router: Router,
    private storageManagementService: StorageManagementService,
    private modalService: ModalService,
    private owcsUtilityService: OwcsUtilityService, public chDetection: ChangeDetectorRef,
    private intakeUtilitiesService: IntakeUtilitiesService,
    private prefillLogicService: PrefillLogicService,
    private contactState: ContactStateService,
    private customEventService: CustomEventService
  ) {
  }

  ngOnInit() {
    this.contactState.updateState(this.isDisabled);
    this.isDisabled = true;
    this.isLOAEnabled = this.storageManagementService.getIntakeSessionData(IntakeSessionConstants.LOA_INDICATOR);
    this.storageManagementService.removeAttrFromIntakeSessionData(BondingIntakeSessionConstants.RS_DEFAULT_MANDATORYOT_RESPONSE, BondingIntakeSessionConstants.INTAKE_TYPE_BONDING);
    if (this.isLOAEnabled) {
      //May be removed in future once mandatory over time question Implemented or manage according to cases
      this.storageManagementService.setIntakeSessionData(IntakeSessionConstants.MANDATORY_OVERTIME_FLAG, false);
      this.mandatedOTNonWorkdayResponse = IntakeSessionConstants.DEFAULT_MANDATED_OT_NONWORKDAY_RESPONSE_FROM_MODAL;
      this.defaultMandatedOTNonWorkdayResponse = IntakeSessionConstants.DEFAULT_MANDATED_OT_NONWORKDAY_RESPONSE
      this.intakeType = IntakeSessionConstants.INTAKE_TYPE_MATERNITY;
    } else {
      this.mandatedOTNonWorkdayResponse = this.bondingSessionConst.DEFAULT_MANDATED_OT_NONWORKDAY_RESPONSE_FROM_MODAL;
      this.intakeType = this.bondingSessionConst.INTAKE_TYPE_BONDING;
      this.defaultMandatedOTNonWorkdayResponse = this.bondingSessionConst.DEFAULT_MANDATED_OT_NONWORKDAY_RESPONSE
    }
    this.mapDaysAndMinuts();
    this.prefillPreviousSelectedData();
    this.getControl();
    this.getOwcsContent();
  }

  ngAfterContentInit(): void {
    this.updateForm();
  }

  updateForm(): void {
    this.chDetection.detectChanges();
  }

  formUpdateAndValidate(): void {
    Object.keys(this.workScheduleListForm.getRawValue()).forEach(eachForm => {
      this.workScheduleListForm.get(eachForm).updateValueAndValidity();
    })
  }

/* To prefill the previously selected Data*/
  prefillPreviousSelectedData() {
      this.workScheduleFormData = this.prefillLogicService.getData(IntakeSessionConstants.WORK_SCHEDULE_PAGE)
      if (this.workScheduleFormData) {
        this.rotationFirstDateWhen_Yes = this.workScheduleFormData?.rotationFirstDateDuringAbsence
        this.mandatoryOTAnswer = this.workScheduleFormData?.mandatedOTAnswer
        let selectedVal: { detail: { [key: string]: boolean | string }[] };
        if (this.workScheduleFormData?.mandatedOTQuestionDisplay) {
          if (this.mandatoryOTAnswer === true) {
            selectedVal = {
              detail: [{ "name": "Yes", "value": "yes", "checked": true },
              { "name": "No", "value": "no", "checked": false }]
            };
          } else if (this.mandatoryOTAnswer === false) {
            selectedVal = {
              detail: [{ "name": "Yes", "value": "yes", "checked": false },
              { "name": "No", "value": "no", "checked": true }]
            }
          }
          this.setMandatedOTAnswer(selectedVal)
        }
        if (this.rotationFirstDateWhen_Yes) {
          const rotationFirstDate = {
            detail:
            {
              dateInput: true,
              isUserUpdate: false,
              rawvalue: '01012024',
              value: this.rotationFirstDateWhen_Yes
            }
          };
          this.selectedDateIntake(rotationFirstDate)
        }
      }
  }

  getControl(): void {
    if (this.storageManagementService.getSessionData(SessionConstants.REGULAR_WORK_SCHEDULE_SET)) {
      this.workScheduleSet = this.storageManagementService.getSessionData(SessionConstants.REGULAR_WORK_SCHEDULE_SET);
    } else {
      this.workScheduleSet = false;
    }
    if (!this.isWorkScheduleConfirmed && !this.workScheduleSet) {
      this.workScheduleListForm.addControl('workScheduleQuestion', new FormControl('', Validators.required));
    }
    if(this.workScheduleFormData?.rotationFirstDateDuringAbsence){
      this.dateIntake = this.workScheduleFormData?.rotationFirstDateDuringAbsence
    }
  }
  onWorkScheduleQuestion(event): void {
    const radioResp = event?.detail[0]?.checked ? '1001' : '1002';
    this.workScheduleDetail = {
      workScheduleQuestion: radioResp,
      workScheduleList: this.regularWorkScheduleDisplay?.empWorkSchedulesList[0]
    }
    if (radioResp === '1002') {
      this.disableButton = true;
      this.storageManagementService.setIntakeSessionData(IntakeSessionConstants.IS_EDIT_WORK_SCHEDULE, true);
    } else {
      this.storageManagementService.setIntakeSessionData(IntakeSessionConstants.IS_EDIT_WORK_SCHEDULE, false);
      this.addOrRemoveMandatedOTQuestion();
    }

    this.workScheduleDetails.emit(this.workScheduleDetail);
    this.formUpdateAndValidate();
    const result = event?.detail.find(item => item.checked);
    this.customEventService.trackRadioButton(this.scheduleQuestionLabel, result?.name);
  }

  addOrRemoveMandatedOTQuestion(): void {
    const fdaDate = this.storageManagementService.getIntakeSessionData(IntakeSessionConstants.FIRST_DAY_ABSENT);
    const mandatedOTAnswer = this.storageManagementService.getIntakeSessionData(this.defaultMandatedOTNonWorkdayResponse, this.intakeType);
    const otApplicable = (this.workScheduleDetail?.workScheduleQuestion === '1001') || mandatedOTAnswer || this.isWorkScheduleConfirmed || this.workScheduleSet;
    this.displayMandatedOTQuestion = this.intakeUtilitiesService.displayMandatedOTQuestionCheck(fdaDate, otApplicable);

    if (this.displayMandatedOTQuestion) {
      this.mandatedOvertimeRadioOptions =
        this.intakeUtilitiesService.setupHukRadioYesNoOptions(this.owcsContentMap?.get(INTAKE_OWCS_IDS.NI_951A_Body), this.owcsContentMap?.get(INTAKE_OWCS_IDS.NI_951B_Body));
      this.workScheduleListForm.addControl('mandatedOvertimeRadio', new FormControl('', Validators.required));
      const reportMandatedOTNonWorkDayModalRespYes = this.storageManagementService.getIntakeSessionData(this.mandatedOTNonWorkdayResponse, this.intakeType) === true;
      const defaultMandatedOTResp = this.storageManagementService.getIntakeSessionData(this.defaultMandatedOTNonWorkdayResponse, this.intakeType) === true;
      const mandatoryOTAnswer = this.workScheduleFormData?.mandatedOTAnswer
      if (reportMandatedOTNonWorkDayModalRespYes || defaultMandatedOTResp || mandatoryOTAnswer) {
        this.mandatedOvertimeRadioOptions[0].checked = true;
        this.mandatedOvertimeRadioOptions[1].checked = false;
      } else if ((this.storageManagementService.getIntakeSessionData(this.defaultMandatedOTNonWorkdayResponse, this.intakeType) === false) || mandatoryOTAnswer === false) {
        this.mandatedOvertimeRadioOptions[0].checked = false;
        this.mandatedOvertimeRadioOptions[1].checked = true;
      }
      const fdaLabel = this.owcsContentMap.get(INTAKE_OWCS_IDS.NI_950_Body);
      if (fdaDate) {
        this.mandateOvertimeFDALabel =
          fdaLabel?.replace(IntakeLabelConstants.DYNAMIC_DAY_AND_DATE_LABEL, this.intakeUtilitiesService.getDayAndDateFromDate(new Date(fdaDate), false));
      }
    } else if (this.workScheduleListForm.get('mandatedOvertimeRadio')) {
      this.workScheduleListForm.removeControl('mandatedOvertimeRadio');
    }
  }

  displayMandatedOTQuestionCheck(fdaDate: string): boolean {
    const mandatedOTAnswer = this.storageManagementService.getIntakeSessionData(this.defaultMandatedOTNonWorkdayResponse, this.intakeType);

    if (this.workScheduleDetail?.workScheduleQuestion === '1001' || mandatedOTAnswer || this.isWorkScheduleConfirmed || this.workScheduleSet) {
      const leaveData: ISelectedLeaveDetails = this.storageManagementService.getIntakeSessionData(BondingIntakeSessionConstants.SELECTED_LEAVE_DETAILS, BondingIntakeSessionConstants.INTAKE_TYPE_BONDING);
      const gapInWorkDay = this.storageManagementService.getIntakeSessionData(this.bondingSessionConst.GAP_IN_WORKDAY, this.bondingSessionConst.INTAKE_TYPE_BONDING);
      const workSchedule = this.intakeUtilitiesService.getWorkScheduleForDate(fdaDate);
      const fdaUpdate = this.storageManagementService.getIntakeSessionData(BondingIntakeSessionConstants.FDA_UPDATE, BondingIntakeSessionConstants.INTAKE_TYPE_BONDING);

      // hide mandate ot question
      if (workSchedule?.RequestedMinutes > workSchedule?.ScheduledMinutes
        || (this.intakeUtilitiesService.isNonWorkDay(fdaDate) && fdaUpdate === false
          && gapInWorkDay === false && leaveData?.sameContinuity?.toLowerCase() === 'true'
        )
      ) {
        // default ot response in session
        if (leaveData?.sameContinuity?.toLowerCase() === 'false'
          || ((leaveData?.sameContinuity?.toLowerCase() === 'true')
            && (leaveData?.continuity?.toLowerCase() === this.appConst.REDUCE_SCHEDULE.toLowerCase())
            && workSchedule?.RequestedMinutes > workSchedule?.ScheduledMinutes
          )
        ) {
          this.storageManagementService.setIntakeSessionData(this.defaultMandatedOTNonWorkdayResponse, true, this.bondingSessionConst.INTAKE_TYPE_BONDING);
        }
        return false;
      }

      // display mandate ot question
      if (this.intakeUtilitiesService.isNonWorkDay(fdaDate)
        && (
          (fdaUpdate === false && gapInWorkDay === true) || (fdaUpdate === true && gapInWorkDay === false)
          || (fdaUpdate === false && gapInWorkDay === false && leaveData?.sameContinuity?.toLowerCase() === 'false')
        )
      ) {
        return true;
      }
    }
  }

  setMandatedOTAnswer(event): void {
    this.mandatoryOTAnswer = event?.detail[0]?.checked
    this.storageManagementService.removeAttrFromIntakeSessionData(this.mandatedOTNonWorkdayResponse, this.intakeType);
    this.mandatoryOTAnswer ? this.storageManagementService.setIntakeSessionData(BondingIntakeSessionConstants.MANDATORY_OT_REDUCE_SCHEDULE, true) : this.storageManagementService.setIntakeSessionData(BondingIntakeSessionConstants.MANDATORY_OT_REDUCE_SCHEDULE, false)
    this.disableButton = event.detail ? false : true;
    const result = event?.detail[0]?.checked ? event.detail[0].name : event.detail[1].name;
    this.customEventService.trackRadioButton('Were you mandated to work overtime on this date', result);
  }

  mapDaysAndMinuts(): void {
    this.storageManagementService.removeAttrFromIntakeSessionData(IntakeSessionConstants.IS_ROTATING_SCHEDULE);
    this.storageManagementService.removeAttrFromIntakeSessionData(IntakeSessionConstants.IS_WEEKLY_SCHEDULE);
    this.storageManagementService.removeAttrFromIntakeSessionData(IntakeSessionConstants.IS_MULTIWEEK_SCHEDULE);
    let daysJson;
    this.dayMapping.forEach(obj => {
      daysJson = obj;
    });
    this.regularWorkSchedule?.empWorkSchedulesList.forEach(regWorkSchedule => {
      this.workScheduleType = regWorkSchedule?.thaaWorkSchduleType;
      const daysInRotation = regWorkSchedule?.daysInRotation || 0;
      this.isRotatingSchedule = (this.workScheduleType === this.appConstant.ROTATING);
      if (this.workScheduleType === this.appConstant.ROTATING) {
        this.storageManagementService.setIntakeSessionData(IntakeSessionConstants.IS_ROTATING_SCHEDULE, this.isRotatingSchedule);
      } else if (this.workScheduleType === this.appConstant.WEEKLY) {
        this.storageManagementService.setIntakeSessionData(IntakeSessionConstants.IS_WEEKLY_SCHEDULE, true);
      } else {
        this.storageManagementService.setIntakeSessionData(IntakeSessionConstants.IS_MULTIWEEK_SCHEDULE, true);
      }
      this.workScheduleType = (this.workScheduleType === this.appConstant.ROTATING) ? daysInRotation + ' ' + this.appConstant.DAY_ROTATION :
      (this.workScheduleType === this.appConstant.MULTI_WEEK) ? this.appConstant.MULTI_WEEKLY + this.appConstant.SCHEDULE :
      this.workScheduleType + this.appConstant.SCHEDULE;
      regWorkSchedule?.employeeWorkSchedules?.forEach(regWork => {
        this.totalWorkMinutes = 0;
        regWork?.weekDetails?.forEach(week => {
          week.dayVal = daysJson[week?.dayNumber];
          week.regularHours = this.regularWorkHours = {
            hours: parseInt((week.workMinutes / 60).toString(), 10),
            minutes: (week.workMinutes % 60)
          };
          this.totalWorkMinutes = this.totalWorkMinutes + week.workMinutes;
        });
        const totalWorkHoursMins = {hours: parseInt(( this.totalWorkMinutes / 60).toString(), 10),
          'minutes': (this.totalWorkMinutes % 60)};
        const hourValue = (totalWorkHoursMins.hours < 10) ? ('0' + totalWorkHoursMins.hours) : totalWorkHoursMins.hours;
        const totalHour = (totalWorkHoursMins.hours === 1) ? (hourValue + this.appLabelConstant.HOUR_LABEL)  : (hourValue + this.appLabelConstant.HOURS_LABEL)
        const minvalue = (totalWorkHoursMins.minutes < 10) ? ('0' + totalWorkHoursMins.minutes) : totalWorkHoursMins.minutes;
        const totalMin = (totalWorkHoursMins.minutes === 1) ? (minvalue + this.appLabelConstant.MINUTE) : (minvalue + this.appLabelConstant.MINUTES)
        this.totalWorkHoursMins = totalHour + totalMin;
        regWork.totalHour = this.totalWorkHoursMins;
      });
     });
    this.regularWorkScheduleDisplay = this.regularWorkSchedule;
  }

  getOwcsContent(): void {
    const owcsContent = this.storageManagementService.getSessionData(IntakeSessionConstants.NI_OWCS_CONTENT);
    if (owcsContent) {
      const owcsContentIds = [
        INTAKE_OWCS_IDS.NI_8855_Body, INTAKE_OWCS_IDS.NI_876_Body,  INTAKE_OWCS_IDS.NI_878_Body,  INTAKE_OWCS_IDS.NI_879_Body,  INTAKE_OWCS_IDS.NI_881_Body,
        INTAKE_OWCS_IDS.NI_8867_Body, INTAKE_OWCS_IDS.NI_8868_Body, INTAKE_OWCS_IDS.NI_8866_Body, INTAKE_OWCS_IDS.NI_951A_Body, INTAKE_OWCS_IDS.NI_951B_Body,
        INTAKE_OWCS_IDS.NI_950_Body,  INTAKE_OWCS_IDS.NI_951_Body,  INTAKE_OWCS_IDS.NI_8891_Body, INTAKE_OWCS_IDS.NI_8892_Body, INTAKE_OWCS_IDS.NI_952_Body,
        INTAKE_OWCS_IDS.NI_952A_Body, INTAKE_OWCS_IDS.NI_952B_Body, INTAKE_OWCS_IDS.NI_952C_Body, INTAKE_OWCS_IDS.NI_952D_Body, INTAKE_OWCS_IDS.NI_952E_Body
      ];
      this.owcsContentMap = this.owcsUtilityService.createOWCSMap(owcsContent, owcsContentIds);
    }
    const owcsContentIE = this.storageManagementService.getSessionData(IntakeSessionConstants.IE_OWCS_CONTENT);
    if (owcsContentIE) {
      this.intakeExtensionOwcsContentMap = this.owcsUtilityService.createOWCSMap(owcsContentIE, TAKING_LEAVE_BONDING_IDS);
    }
    const workScheduleTypeLabel = this.owcsContentMap.get(INTAKE_OWCS_IDS.NI_879_Body);
    this.workScheduleTypeLabel = workScheduleTypeLabel ? workScheduleTypeLabel.split(':')[0] + ':' : '';
    this.scheduleQuestionLabel = this.owcsContentMap.get(INTAKE_OWCS_IDS.NI_881_Body);
    const firstDayAbsentValue = this.storageManagementService.getIntakeSessionData(IntakeSessionConstants.FIRST_DAY_ABSENT) ? this.storageManagementService.getIntakeSessionData(IntakeSessionConstants.FIRST_DAY_ABSENT) : '';
    this.rotatingScheduleQues =  this.owcsContentMap.get(INTAKE_OWCS_IDS.NI_8866_Body)?.replace('[MM/DD/YYYY]', firstDayAbsentValue) || '';
    this.fdaDate = (this.storageManagementService.getIntakeSessionData(IntakeSessionConstants.FIRST_DAY_ABSENT)) ? this.storageManagementService.getIntakeSessionData(IntakeSessionConstants.FIRST_DAY_ABSENT) : '';
    this.confirmWorkScheduleRadioOptions =
      this.intakeUtilitiesService.setupHukRadioYesNoOptions(this.owcsContentMap?.get(INTAKE_OWCS_IDS.NI_8891_Body), this.owcsContentMap?.get(INTAKE_OWCS_IDS.NI_8892_Body));
    let editWorkSchedule = this.storageManagementService.getIntakeSessionData(IntakeSessionConstants.IS_EDIT_WORK_SCHEDULE);
    if (editWorkSchedule === null || editWorkSchedule === undefined) {
      editWorkSchedule = this.storageManagementService.getSessionData(IntakeSessionConstants.IS_EDIT_WORK_SCHEDULE);
      this.storageManagementService.setIntakeSessionData(IntakeSessionConstants.IS_EDIT_WORK_SCHEDULE, editWorkSchedule);
    }
    let changeFDA_Workschedule = this.storageManagementService.getIntakeSessionData(IntakeSessionConstants.CHANGE_FDA_WORKSCHEDULE);
    if (changeFDA_Workschedule === null || changeFDA_Workschedule === undefined) {
      changeFDA_Workschedule = this.storageManagementService.getSessionData(IntakeSessionConstants.CHANGE_FDA_WORKSCHEDULE);
      this.storageManagementService.setIntakeSessionData(IntakeSessionConstants.CHANGE_FDA_WORKSCHEDULE, changeFDA_Workschedule);
    }

    this.isFDAANonWorkDay = this.storageManagementService.getIntakeSessionData(SessionConstants.IS_FDA_A_NON_WORKDAY);
    this.addOrRemoveMandatedOTQuestion();
  }

  editWorkSchedule(): void {
    const checkLOA = this.storageManagementService.getIntakeSessionData(IntakeSessionConstants.LOA_INDICATOR);
    const bondingFlowApplicable = this.storageManagementService.getIntakeSessionData(this.bondingSessionConst.BONDING_FLOW, this.bondingSessionConst.INTAKE_TYPE_BONDING);
    const extensionBonding = this.storageManagementService.getIntakeSessionData(BondingIntakeSessionConstants.EXTENSION_BONDING, BondingIntakeSessionConstants.INTAKE_TYPE_BONDING);
    const transitionBonding = this.storageManagementService.getIntakeSessionData(BondingIntakeSessionConstants.TRANSITION_TO_BONDING, BondingIntakeSessionConstants.INTAKE_TYPE_BONDING);
    if (bondingFlowApplicable && !transitionBonding) {
      this.storageManagementService.setIntakeSessionData(IntakeSessionConstants.IS_EDIT_WORK_SCHEDULE, true);
      extensionBonding ? this.router.navigate([INTAKE_ROUTES.WORKSCHEDULE_EDIT_EXTENSION]) : this.router.navigate([INTAKE_ROUTES.WORKSCHEDULE_EDIT]);
    } else if(checkLOA){
      this.router.navigate([INTAKE_ROUTES.WORK_SCHEDULE_EDIT_LOA]);
    }
  }

  /***
   * validate date from huk-date picker
   * @param event
   */
  selectedDateIntake(event): void {
    const selected = new Date(event?.detail?.value);
    let enabledButton = false;
    if (new Date(event?.detail?.value) > new Date(this.fdaDate)) {
      enabledButton = true;
    } else {
      enabledButton = false;
    }
    this.rotationFirstDateWhen_Yes = event?.detail?.value;
    this.invalidDate = !(event?.detail?.rawvalue?.length === 8 && selected.getTime() && !enabledButton);
    this.formUpdateAndValidate();
  }

  continue(): void {
    const checkLOA = this.storageManagementService.getIntakeSessionData(IntakeSessionConstants.LOA_INDICATOR);
    this.disableButton = true;
    this.isSpinnerDisabled = 'false';
    this.isDisabled = true;
    this.contactState.updateState(this.isDisabled);
    if (checkLOA) {
      this.continueMaternityFlow();
    } else {
      if (this.displayMandatedOTQuestion) {
        this.storageManagementService.setIntakeSessionData(
          this.bondingSessionConst.DEFAULT_MANDATED_OT_NONWORKDAY_RESPONSE, this.mandatoryOTAnswer, this.bondingSessionConst.INTAKE_TYPE_BONDING);
      }
      this.storageManagementService.setIntakeSessionData(IntakeSessionConstants.ROTATION_FIRST_DATE_DURING_ABSENCE, this.rotationFirstDateWhen_Yes, BondingIntakeSessionConstants.INTAKE_TYPE_BONDING);
      const extensionBonding = this.storageManagementService.getIntakeSessionData(BondingIntakeSessionConstants.EXTENSION_BONDING, BondingIntakeSessionConstants.INTAKE_TYPE_BONDING);
      const mandatedOTanswer = this.storageManagementService.getIntakeSessionData(this.bondingSessionConst.DEFAULT_MANDATED_OT_NONWORKDAY_RESPONSE, BondingIntakeSessionConstants.INTAKE_TYPE_BONDING);
      const leaveData: ISelectedLeaveDetails = this.storageManagementService.getIntakeSessionData(BondingIntakeSessionConstants.SELECTED_LEAVE_DETAILS, BondingIntakeSessionConstants.INTAKE_TYPE_BONDING);
      const selectedData = {
        rotationFirstDateDuringAbsence: this.isRotatingSchedule ? this.rotationFirstDateWhen_Yes : '',
        mandatedOTAnswer: (this.displayMandatedOTQuestion) ? this.mandatoryOTAnswer : '',
        mandatedOTQuestionDisplay: (this.isRotatingSchedule && this.displayMandatedOTQuestion)
      }
      if (this.dateIntake) {
        this.storageManagementService.setIntakeSessionData(IntakeSessionConstants.ROTATION_FIRST_DATE_DURING_ABSENCE, this.dateIntake, BondingIntakeSessionConstants.INTAKE_TYPE_BONDING);
      }
      this.prefillLogicService.saveData(IntakeSessionConstants.WORK_SCHEDULE_PAGE, selectedData)
      if (((leaveData && extensionBonding) || !leaveData) && !mandatedOTanswer && this.displayMandatedOTQuestion) {
        this.displayMandatedOT = true;
      } else {
        this.onContinue.emit();
      }
    }
  }

  hideOTModalOnClose(): void {
    this.displayMandatedOT = false;
    this.isSpinnerDisabled = 'true';
  }

  /***
   * To get event for invalid date from huk-date-picker
   * @param event
   */
  onHukDatePickerError(event): void {
    this.invalidDate = true;
  }

  continueMaternityFlow(): void {
    if (this.displayMandatedOTQuestion) {
      this.storageManagementService.setIntakeSessionData(
        IntakeSessionConstants.DEFAULT_MANDATED_OT_NONWORKDAY_RESPONSE, this.mandatoryOTAnswer, IntakeSessionConstants.INTAKE_TYPE_MATERNITY);
    }

    this.storageManagementService.setIntakeSessionData(IntakeSessionConstants.ROTATION_FIRST_DATE_DURING_ABSENCE, this.rotationFirstDateWhen_Yes, IntakeSessionConstants.INTAKE_TYPE_MATERNITY);
    const mandatedOTanswer = this.storageManagementService.getIntakeSessionData(IntakeSessionConstants.DEFAULT_MANDATED_OT_NONWORKDAY_RESPONSE, IntakeSessionConstants.INTAKE_TYPE_MATERNITY);
    const selectedData: IPrefillDetails = {
      rotationFirstDateDuringAbsence: this.isRotatingSchedule ? this.rotationFirstDateWhen_Yes : '',
      mandatedOTAnswer: (this.displayMandatedOTQuestion) ? this.mandatoryOTAnswer : '',
      mandatedOTQuestionDisplay: (this.isRotatingSchedule && this.displayMandatedOTQuestion)
    }

    if (this.dateIntake) {
      this.storageManagementService.setIntakeSessionData(IntakeSessionConstants.ROTATION_FIRST_DATE_DURING_ABSENCE, this.dateIntake, IntakeSessionConstants.INTAKE_TYPE_MATERNITY);
    }

    this.prefillLogicService.saveData(IntakeSessionConstants.WORK_SCHEDULE_PAGE, selectedData)
    this.addAndRemoveReducedSchPrefillFlag(true);
    if (this.displayMandatedOTQuestion && !mandatedOTanswer) {
      this.displayMandatedOT = true;
    } else {
      this.onContinue.emit();
    }
  }

  addAndRemoveReducedSchPrefillFlag(isSaveInSession: boolean): void {
    const isMandatoryOT = this.storageManagementService.getIntakeSessionData(IntakeSessionConstants.MANDATORY_OVERTIME_FLAG);
    if (!isMandatoryOT) {
      isSaveInSession ? this.intakeUtilitiesService.setReducedSchPrefillFlag() : this.storageManagementService.removeAttrFromIntakeSessionData(IntakeSessionConstants.REDUCED_SCHEDULE_PREFILL, IntakeSessionConstants.INTAKE_TYPE_MATERNITY);
    }
  }

  resetMandateOT(): void {
    this.mandatedOvertimeRadioOptions[0].checked = false;
    this.mandatedOvertimeRadioOptions[1].checked = false;
    this.workScheduleListForm.get('mandatedOvertimeRadio').setValue('');
    this.addAndRemoveReducedSchPrefillFlag(false);
  }
}
