import {AfterContentChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { IHukHoursMinsObject } from '@app/cxr/modules/intake/shared/models/hours-mins-object.model';
import { Intake_Constants } from '../../constants/intake.constants';
import { IMultiDropdownError, IMultiDropdownInput, IMultiDropdownInputTitles, IMultiDropdownOption } from '@app/shared/models/multi-dropdown.model';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-time-picker-dropdown',
  templateUrl: './time-picker-dropdown.component.html',
  styleUrls: ['./time-picker-dropdown.component.scss']
})
export class TimePickerDropdownComponent implements OnInit, AfterContentChecked {
    @Input() timeLabel: string;
    @Input() reset: boolean;
    @Input() labelId: string;
    @Input() placeHolder: string;
    @Input() selectedTime: IHukHoursMinsObject;
    @Output() hourMinUnitValue = new EventEmitter();
    @Input() multiDropdownTitles: IMultiDropdownInputTitles;
    @Input() multiDropdownErrorMsg: IMultiDropdownError;
    @Input() multiDropdownOption: IMultiDropdownOption;
    @Input() hourMinUnitForm: FormGroup;
    @Input() showUnitDropdown: boolean;
    @Input() magnoliaContentMap: Map<string, string> = new Map();
    value: number = 0;
    constructor(private ref: ChangeDetectorRef) { }
    isHourInvalid: boolean;
    ngOnInit() {
        this.createAndSetTimeForm();
    }
    ngAfterContentChecked(): void {
        if ((this.hourMinUnitForm.controls.hour.value === this.multiDropdownOption?.hourList[this.multiDropdownOption.hourList.length - 1]?.value) && (this.multiDropdownOption?.minuteList?.length === 1)) {
            this.hourMinUnitForm.controls.minute.setValue(Intake_Constants.MINUTE_MIN_VAL);
            this.selectedTime['minute'] = Intake_Constants.MINUTE_MIN_VAL;
            this.hourMinUnitForm.controls.minute.disable();
        } else {
            this.hourMinUnitForm.controls.minute.enable();
        }
        this.ref.detectChanges();
    }
    createAndSetTimeForm(): void{
        const formControls = {};
        for (const field of Object.keys(this.selectedTime)) {
                formControls[field] = new FormControl(this.selectedTime[field], Validators.required);
        }
        if(!this.showUnitDropdown){
            delete formControls['unit'];
        }
        this.hourMinUnitForm = new FormGroup(formControls);
    }

    absenceSectionValidityEmitter(): void {
        if (this.hourMinUnitForm.valid) {
            this.hourMinUnitValue.emit(this.selectedTime);
        }
    }
    setPartialDayHr(val: string): void {
        this.hourMinUnitForm.controls.hour.setValue(val);
        const selectedHour = this.hourMinUnitForm.controls.hour.value;
        if (selectedHour) {
            this.selectedTime['hour'] = selectedHour.trim();
        }
        this.absenceSectionValidityEmitter();
        this.isHourInvalid=this.hourMinUnitForm.controls.hour.invalid;
      }
      setPartialDayMin(val: string): void {
        this.hourMinUnitForm.controls.minute.setValue(val);
        const selectedMinute = this.hourMinUnitForm.controls.minute.value;
        if (selectedMinute) {
            this.selectedTime['minute'] = selectedMinute.trim();
        }
        this.absenceSectionValidityEmitter();
      }
      setPartialDayUnit(val: string): void {
        this.hourMinUnitForm.controls.unit.setValue(val);
        const selectedUnit = this.hourMinUnitForm.controls.unit.value;
        if (selectedUnit) {
            this.selectedTime['unit'] = selectedUnit.trim();
        }
        this.absenceSectionValidityEmitter();
      }
      checkHourError(): void{
        this.isHourInvalid=this.hourMinUnitForm.controls.hour.invalid;
      }
}
