import { Location } from "@angular/common";
import {
  AfterContentChecked,
  AfterContentInit,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { NavigationExtras, Router } from "@angular/router";
import {
  BondingIntakeSessionConstants,
  IntakeSessionConstants,
} from "@app/cxr/modules/intake/shared/constants/intakeSession.constants";
import { OverpaymentSessionConstants } from "@app/cxr/overpayment/shared/constants/overpayment-session.constants";
import { MagnoliaPageConstants } from "@app/shared/constants/magnoliaPage.constants";
import { CustomError } from "@app/shared/models/customError.model";
import { EmployeeStatusService } from "@app/shared/services/employee-status.service";
import { FMLIStatusService } from "@app/shared/services/fmli-status.service";
import { AetnaPaymentsService } from "@shared/services/aetnaPayments.service";
import { ToggleService } from "@shared/services/toggle.service";
import { parallel } from "async";
import { ReplaySubject, Subject, Subscription, of } from "rxjs";
import { filter, map, mergeMap, takeUntil } from "rxjs/operators";
import { AppConstants } from "../../shared/constants/app.constants";
import { AppLabelConstants } from "../../shared/constants/appLabel.constants";
import { ErrorConstants } from "../../shared/constants/error.constants";
import { SessionConstants } from "../../shared/constants/session.constants";
import { Feature, FeatureTurnOnOffResponse } from "../../shared/models/Feature";
import { AppLeaveInfo, PolicyAdminLeaveResponse } from "../../shared/models/LeaveMappings.model";
import {
  ClaimPaymentRequest,
  ClaimantPaymentInfo,
  ItotalPayments,
} from "../../shared/models/Payment.model";
import { LoginRouting } from "../../shared/models/loginRouting.model";
import { NotificationDetails } from "../../shared/models/notificationDetails.model";
import { PHARecordsAvailability } from "../../shared/models/pha.model";
import { RelatedClaims } from "../../shared/models/relatedClaims.model";
import { User } from "../../shared/models/user.model";
import { CIIFeatureService } from "../../shared/services/CII-feature.service";
import { AuthStateService } from "../../shared/services/auth-state.service";
import { DocumentService } from "../../shared/services/claim-documents.service";
import { PaymentsHistoryService } from "../../shared/services/claim-payment-history.service";
import { ClaimServiceService } from "../../shared/services/claim-service.service";
import { ClaimWizardService } from "../../shared/services/claim-wizard.service";
import { ContactsService } from "../../shared/services/contacts.service";
import { ContentService } from "../../shared/services/content.service";
import { LeaveService } from "../../shared/services/leave-service.service";
import { LogonService } from "../../shared/services/logon.service";
import { MessageRibbonService } from "../../shared/services/message-ribbon.service";
import { ModalService } from "../../shared/services/modal.service";
import { MyBenefitsTitleService } from "../../shared/services/myBenefitsTitleService.service";
import { NotificationRegistrationService } from "../../shared/services/notification-registration.service";
import { NotificationService } from "../../shared/services/notifications.service";
import { PersonService } from "../../shared/services/people.service";
import { phaOverviewService } from "../../shared/services/pha-overview.service";
import { ReferenceHelperService } from "../../shared/services/reference-helper.service";
import { ResponseErrorHandlerService } from "../../shared/services/response-error-handler.service";
import { SelfLinkingService } from "../../shared/services/self-linking.service";
import { SessionManagementService } from "../../shared/services/session-management.service";
import { StorageManagementService } from "../../shared/services/storage-management.service";
import { CustomEventService } from "../../shared/services/tealium/custom-event.service";
import { LeaveDynatraceAction } from "../../shared/utils/leaveDynatraceAction";
import { AppLeaveIntakeConstants } from "../leave-claim-intake-flow/leave-intake.constants";
import { INTAKE_OWCS_IDS } from "../modules/intake/shared/JSON/intake-owcs.constants";
import { IntakeLabelConstants } from "../modules/intake/shared/constants/intake-label.constants";
import { IntakeOWCSContentService } from "../modules/intake/shared/services/intake-owcs-content.service";
import { IntakeUtilitiesService } from "../modules/intake/shared/services/intake-utilities.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["home.component.scss"],
})
export class HomeComponent
  extends LeaveDynatraceAction
  implements OnInit, AfterViewInit, AfterContentInit, AfterContentChecked, OnDestroy {
  /* Simplified Parameters needed for landing page. This list will grow test */
  public isLeaveRealatedWithDefaultCaseId = false;
  public newClaimIntakeAbsenceRights;
  public isAbsencePlanningMaternity: boolean = false;
  public userClaims: RelatedClaims[] = [];
  defaultCaseId;
  profileInfo: any = {};
  activeInd: boolean;
  public claimLink = AppLabelConstants.URL_CLAIM_HISTORY;
  public claimWizard = AppLabelConstants.URL_CLAIM_WIZARD_1;
  public welcomeMessage = AppLabelConstants.WELCOME_MESSAGE;
  tncModal = AppLabelConstants.UPDATE_TCS_ALERT_MODAL;
  public continueclicked: boolean = false;
  insuredClaims_nonRelatedClaims: RelatedClaims[];
  insuredClaims_RelatedClaims: RelatedClaims[];
  public insuredLeaves;
  public currUser: User;
  termsAndConditionsExpiredFlag: number;
  isClaimExist = false;
  siteWideOutdated: boolean;
  activeIndicator = 0;
  public partyId;
  public pageId;
  public newIntakeOwcsContent;
  public jsonBOM: any = {};
  public isMBActive: boolean = true;
  isLeaveRequestDisplay: boolean = false;
  isELGweak = false;
  public isClaimHistoryApplicable = false;
  public isPulseRecordExists = false;
  getSysDownMsg;
  public preRendering = "skeleton-container";
  public claimsReturned = false;
  public isUserExist = false;
  public owcsContent;
  public isDisabilityContactCompleted = false;
  public loadHelpWidget = false;
  public noOpenClaims = false;
  public isPromptDisplay: boolean = false;
  overviewSysDownKey: string = "";
  sourcePartyDetailsList: any[] = [];
  public areAllClaimsLeaveless: boolean = false;
  eligibilityId: string;
  personId: string;
  isUserDataSet: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  public isLeaveAssociatedWithClaim: boolean = false;
  public isDefaultCaseThaa: boolean = false;
  public appLabelConstant = AppLabelConstants;
  public isToggleOn = false;
  claimPaymentRequest: ClaimPaymentRequest = new ClaimPaymentRequest();
  dcsPaymentSubscription;
  aaPaymentSubscription;
  _destroy$: Subject<void> = new Subject();
  overpaymentApplicable: boolean = false;
  preferredNameExists: boolean = null;
  preferredName: string = "";
  preferredNameSubscription: Subscription;
  employeeStatusSubscription: Subscription;
  maternityToggleParmsSubcription: Subscription;
  fmliStatusSubscription: Subscription;
  isLoading: boolean = false;
  disableButton: boolean = false;
  isPaymentDisabled: boolean = false;
  paymentFeatureToggleSubscription: Subscription;
  public isTaxDisabled = false;
  taxFormFeatureToggleSubscription: Subscription;
  startANewClaimFeatureToggleSubscription: Subscription;
  missingInfoFeatureToggleSubscription: Subscription;
  isStartANewClaimPostAuthDisabled: boolean = false;
  isMissingInfoDisabled: boolean = false;
  isFileUploadDisabled: boolean = false;
  fileUploadDisabledToggleSubscription: Subscription;
  mobileNumber: string;
  customError: CustomError = Object.assign({});
  navigationExtras: NavigationExtras = {
    state: {
      data: this.customError,
    },
  };
  fmliStatus: any;
  leaveCalcFT;

  constructor(
    private contactService: ContactsService,
    private contentService: ContentService,
    private authStateService: AuthStateService,
    private selfLinkingService: SelfLinkingService,
    private storageManagementService: StorageManagementService,
    private personService: PersonService,
    private employeeStatusService: EmployeeStatusService,
    private paymentHistoryService: PaymentsHistoryService,
    private notificationRegistrationService: NotificationRegistrationService,
    private modalService: ModalService,
    private claimService: ClaimServiceService,
    private profileService: PersonService,
    private phaOverview: phaOverviewService,
    private router: Router,
    private sessionManagement: SessionManagementService,
    private errorHandlerService: ResponseErrorHandlerService,
    private logonService: LogonService,
    private messageRibbonService: MessageRibbonService,
    private claimWizardService: ClaimWizardService,
    private notificationService: NotificationService,
    private location: Location,
    private myBenefitsTitleService: MyBenefitsTitleService,
    private leaveService: LeaveService,
    private customEventService: CustomEventService,
    private docService: DocumentService,
    private ciiFeature: CIIFeatureService,
    private toggle: ToggleService,
    private aetnaPaymentsService: AetnaPaymentsService,
    private intakeUtilitiesService: IntakeUtilitiesService,
    private referenceHelper: ReferenceHelperService,
    private intakeOWCSService: IntakeOWCSContentService,
    private cdref: ChangeDetectorRef,
    private fmliStatusService: FMLIStatusService
  ) {
    super(storageManagementService);
  }

  ngAfterViewInit() {
    this.isUserDataSet.subscribe((isUserDataSet: boolean) => {
      if (isUserDataSet) {
        this.manageNotifications();
      }
    });
  }

  ngAfterContentChecked(): void {
    this.cdref.detectChanges();
  }

  resolve() {
    this.pageId = this.contentService.getPageId(IntakeLabelConstants.NEW_INTAKE_PAGEID);
    this.newIntakeOwcsContent = this.storageManagementService.getSessionData(
      IntakeSessionConstants.NI_OWCS_CONTENT
    );
    const observer = new Subject<any>();
    if (!this.newIntakeOwcsContent) {
      parallel(
        {
          intakeOwcsContentData: (data) => {
            this.contentService
              .getOWCSResponse(this.pageId, IntakeLabelConstants.NEW_INTAKE_MOCK_OWCS_USERNAME)
              .pipe(
                filter((contentResponse) => !!contentResponse),
                takeUntil(this._destroy$)
              )
              .subscribe((contentResponse) => {
                data(null, contentResponse);
                this.storageManagementService.setSessionData(
                  IntakeSessionConstants.NI_OWCS_CONTENT,
                  contentResponse
                );
                this.getRouteData();
                this.router.navigate([AppLabelConstants.URL_CUSTOMERROR], this.navigationExtras);
              });
          },
        },
        (err: string, results: any) => {
          observer.next(results);
          observer.complete();
        }
      );
      return observer.asObservable();
    } else {
      this.getRouteData();
      this.router.navigate([AppLabelConstants.URL_CUSTOMERROR], this.navigationExtras);
    }
  }

  ngOnInit() {
    if (this.storageManagementService.getSessionData(SessionConstants.CLAIM_MOBILE)) {
      this.mobileNumber = this.storageManagementService.getSessionData(
        SessionConstants.CLAIM_MOBILE
      );
    }
    if (
      this.storageManagementService.getSessionData(AppConstants.IS_PAYMENT_DISABLED) === undefined
    ) {
      this.paymentFeatureToggle();
    } else {
      this.isPaymentDisabled = this.storageManagementService.getSessionData(
        AppConstants.IS_PAYMENT_DISABLED
      );
    }

    /* Initialize the App Data object. Taken from the logon service*/
    if (!this.storageManagementService.retrieveSessionObject(SessionConstants.APP_DATA)) {
      this.initializeObjects();
    } else {
      this.storageManagementService.setAppData(
        SessionConstants.CURRENT_PAGE,
        SessionConstants.CLAIM_OVERVIEW_INHOME
      );
    }
    this.docService.removeSessionFiles();
    this.myBenefitsTitleService.setTitle("");
    this.intakeUtilitiesService.resetWorkScheduleList();
    sessionStorage.removeItem(SessionConstants.MISSING_INF_DIV);
    sessionStorage.removeItem(SessionConstants.SAVE_CHANGES_CLICKED_RWS);
    this.storageManagementService.removeAttrFromSessionData(SessionConstants.PRODUCT_NAV);
    this.storageManagementService.removeAttrFromSessionData(SessionConstants.SORTED_LEAVE_PLANS);
    this.storageManagementService.removeAttrFromSessionData(SessionConstants.LEAVE_PLAN_BALANCE);
    this.storageManagementService.removeAttrFromSessionData(SessionConstants.CLAIM_MORE_DETAILS);
    this.storageManagementService.removeAttrFromIntakeSessionData(
      IntakeSessionConstants.SHOW_SECONDARY_DROPDOWN_DEFAULT_VALUES
    );
    this.storageManagementService.removeAttrFromIntakeSessionData(
      IntakeSessionConstants.FIRST_DAY_ABSENT
    );
    this.storageManagementService.removeAttrFromIntakeSessionData(
      IntakeSessionConstants.SELECTED_SECONDARY_RELATIONSHIP
    );
    this.storageManagementService.removeAttrFromIntakeSessionData(
      IntakeSessionConstants.SELECTED_PRIMARY_RELATIONSHIP
    );
    this.storageManagementService.removeSessionObject(SessionConstants.RELATED_CLAIMS);
    this.storageManagementService.setSessionData(SessionConstants.RTW_REDUCED_SCHEDULE, "false");
    this.storageManagementService.setSessionData(SessionConstants.IS_HOME_VISITED, "true");
    this.storageManagementService.removeAttrFromSessionData(
      OverpaymentSessionConstants.HIDE_OVERPAYMENT_ALERT_ON_LOGOUT
    );
    /* Start the login Process by pulling in the sitesmap call*/
    this.contentService.getSiteMap().subscribe((val) => {
      if (val) {
        this.storageManagementService.setSessionData(SessionConstants.SITE_MAP_DATA, val);
        this.contentService.isSiteMapReady.next(true);
        this.absenceContentFetch();
        this.owcsContentFetch();
        this.owcsErrorMsgContentFetch();
        this.owcsErrorMsgContentFetchFP();
        this.logonStep2();
        this.getFeatureTurnOnOffInfo();
      }
    });

    if (
      this.storageManagementService.getSessionData(AppConstants.IS_TAX_FORM_DISABLED) === undefined
    ) {
      this.taxFormFeatureToggle();
    } else {
      this.isTaxDisabled = this.storageManagementService.getSessionData(
        AppConstants.IS_TAX_FORM_DISABLED
      );
    }
    if (
      this.storageManagementService.getSessionData(
        AppConstants.IS_START_A_NEW_CLAIM_POST_AUTH_DISABLED
      ) === undefined
    ) {
      this.startANewClaimPostAuthFeatureToggle();
    } else {
      this.isStartANewClaimPostAuthDisabled = this.storageManagementService.getSessionData(
        AppConstants.IS_START_A_NEW_CLAIM_POST_AUTH_DISABLED
      );
    }
    if (
      this.storageManagementService.getSessionData(AppConstants.IS_MISSING_INFO_DISABLED) ===
      undefined
    ) {
      this.missingInfoFeatureToggle();
    } else {
      this.isMissingInfoDisabled = this.storageManagementService.getSessionData(
        AppConstants.IS_MISSING_INFO_DISABLED
      );
    }
    if (
      this.storageManagementService.getSessionData(AppConstants.IS_UPLOAD_DISABLED) === undefined
    ) {
      this.uploadButtonFeatureToggle();
    } else {
      this.isFileUploadDisabled = this.storageManagementService.getSessionData(
        AppConstants.IS_UPLOAD_DISABLED
      );
    }
  }

  getRouteData(): void {
    this.customError.IscustomErrorContent = true;
    this.customError.customErrorIcon = AppLabelConstants.CUSTOM_ERROR_ICON;
    this.customError.customErrorHeader = this.intakeOWCSService.getNewMaternityIntakeOwcsContent(
      INTAKE_OWCS_IDS.NI_100_Body
    );
    this.customError.customErrorLabel = this.intakeOWCSService.getNewMaternityIntakeOwcsContent(
      INTAKE_OWCS_IDS.NI_944_Body
    );
    this.customError.customErrorBody = this.intakeOWCSService.getNewMaternityIntakeOwcsContent(
      INTAKE_OWCS_IDS.NI_945_Body
    );
    this.customError.customErrorReturntoHome = this.intakeOWCSService
      .getNewMaternityIntakeOwcsContent(INTAKE_OWCS_IDS.NI_947_Body)
      ?.split("<div>")[1]
      ?.split("</div>")[0];
    if (this.mobileNumber !== undefined) {
      this.customError.customErrorDesc = this.intakeOWCSService
        .getNewMaternityIntakeOwcsContent(INTAKE_OWCS_IDS.NI_946_Body)
        ?.replace("[Case Specific Claim Office Number]", this.mobileNumber);

      if (screen.width < 480 || screen.height < 480) {
        this.customError.customErrorDesc = this.intakeOWCSService
          .getNewMaternityIntakeOwcsContent(INTAKE_OWCS_IDS.NI_946_Body)
          ?.replace(
            "[Case Specific Claim Office Number]",
            '<a href="tel:' + this.mobileNumber + '">' + this.mobileNumber + "</a>"
          );
      }
    } else {
      this.customError.customErrorDesc = this.intakeOWCSService.getNewMaternityIntakeOwcsContent(
        INTAKE_OWCS_IDS.NI_946A_Body
      );
      if (screen.width < 480 || screen.height < 480) {
        var phone = this.customError.customErrorDesc?.match(
          "\\+?\\(?\\d*\\)? ?\\(?\\d+\\)?\\d*([\\s./-]\\d{2,})+"
        )[0];
        this.customError.customErrorDesc = this.customError.customErrorDesc?.replace(
          phone,
          '<a href="tel:' + phone + '">' + phone + "</a>"
        );
      }
    }
  }
  // Getting toggle values from OWCS to toggle features
  paymentFeatureToggle() {
    this.paymentFeatureToggleSubscription = this.errorHandlerService
      .getSysDownTime(true, AppConstants.IS_PAYMENT_DISABLED)
      .subscribe((val) => {
        if (val.message && val.message.length > 0) {
          this.isPaymentDisabled =
            val.message[0].value.toLowerCase().indexOf(AppConstants.TRUE) !== -1 ? true : false;
        }
        this.storageManagementService.setSessionData(
          AppConstants.IS_PAYMENT_DISABLED,
          this.isPaymentDisabled
        );
      });
  }

  taxFormFeatureToggle() {
    this.taxFormFeatureToggleSubscription = this.errorHandlerService
      .getSysDownTime(true, AppConstants.IS_TAX_FORM_DISABLED)
      .subscribe((val) => {
        if (val.message && val.message.length > 0) {
          this.isTaxDisabled =
            val.message[0].value.toLowerCase().indexOf(AppConstants.TRUE) !== -1 ? true : false;
        }
        this.storageManagementService.setSessionData(
          AppConstants.IS_TAX_FORM_DISABLED,
          this.isTaxDisabled
        );
      });
  }
  startANewClaimPostAuthFeatureToggle() {
    this.startANewClaimFeatureToggleSubscription = this.errorHandlerService
      .getSysDownTime(true, AppConstants.IS_START_A_NEW_CLAIM_POST_AUTH_DISABLED)
      .subscribe((val) => {
        if (val.message && val.message.length > 0) {
          this.isStartANewClaimPostAuthDisabled =
            val.message[0].value.toLowerCase().indexOf(AppConstants.TRUE) !== -1 ? true : false;
        }
        this.storageManagementService.setSessionData(
          AppConstants.IS_START_A_NEW_CLAIM_POST_AUTH_DISABLED,
          this.isStartANewClaimPostAuthDisabled
        );
      });
  }
  missingInfoFeatureToggle() {
    this.missingInfoFeatureToggleSubscription = this.errorHandlerService
      .getSysDownTime(true, AppConstants.IS_MISSING_INFO_DISABLED)
      .subscribe((val) => {
        if (val.message && val.message.length > 0) {
          this.isMissingInfoDisabled =
            val.message[0].value.toLowerCase().indexOf(AppConstants.TRUE) !== -1 ? true : false;
        }
        this.storageManagementService.setSessionData(
          AppConstants.IS_MISSING_INFO_DISABLED,
          this.isMissingInfoDisabled
        );
      });
  }
  uploadButtonFeatureToggle() {
    this.fileUploadDisabledToggleSubscription = this.errorHandlerService
      .getSysDownTime(true, AppConstants.IS_UPLOAD_DISABLED)
      .subscribe((val) => {
        if (val.message && val.message.length > 0) {
          this.isFileUploadDisabled =
            val.message[0].value.toLowerCase().indexOf(AppConstants.TRUE) !== -1 ? true : false;
        }
        this.storageManagementService.setSessionData(
          AppConstants.IS_UPLOAD_DISABLED,
          this.isFileUploadDisabled
        );
      });
  }
  absencePlanningToggle(toggleList: any[]): void {
    setTimeout(() => {
      this.isAbsencePlanningMaternity =
        this.isAbsencePlanningFeatureToggleEnabled(toggleList) &&
        this.isAbsencePlanningRightEnabled();
    }, 1000);
  }
  isAbsencePlanningFeatureToggleEnabled(toggleList: any[]): boolean {
    return (
      toggleList?.find(
        (toggles) =>
          toggles.toggle.toLowerCase() === AppConstants.IS_ABSENCE_PLANNING_MATERNITY.toLowerCase()
      )?.state ?? false
    );
  }
  isAbsencePlanningRightEnabled(): boolean {
    return this.intakeUtilitiesService.getCIIFeatureData()?.allowAbsencePlanning === 1;
  }

  isNonLoaCalcDisabled(toggleList: any[]): void {
    this.leaveCalcFT =
      toggleList?.find(
        (toggles) =>
          toggles.toggle.toLowerCase() === AppConstants.IS_NON_LOA_CALC_DISABLED.toLowerCase()
      )?.state ?? false;
  }

  logonStep2() {
    this.logonService.validateLogin(null).subscribe(
      (data: LoginRouting) => {
        if (data && data.reRouting()) {
          super.ngAfterViewInit();
          this.router.navigate([data.newRouting()]);
        } else {
          this.jsonBOM = JSON.parse(
            this.storageManagementService.retrieveSessionObject(SessionConstants.APP_DATA)
          ).appData;
          this.currUser = this.jsonBOM.userData;
          if (this.currUser.sourcePartyDetailsList) {
            const stateCode = this.currUser.sourcePartyDetailsList[0].stateCode;
            this.storageManagementService.setSessionData(
              SessionConstants.USER_STATE_CODE,
              stateCode
            );
          }

          if (this.currUser.caseId === AppConstants.CASE_ID_463726) {
            this.getPreferredName();
          } else {
            this.preferredNameExists = false;
          }
          if (this.jsonBOM.userData) {
            this.isUserExist = true;
          }
          this.isUserDataSet.next(true);
          //To reset flag to show not in case of claim history
          this.resetClaimRepresentativeFlag();
          //Block load for vanity urls
          let loadCheck = true;

          //Vanity URL Amazon
          if (this.currUser.caseId === AppConstants.CASE_ID_1225118) {
            //Check if already contains base href if no route
            if (!this.location.isCurrentPathEqualTo(AppLabelConstants.URL_AMAZON_HOME)) {
              //Stop load
              loadCheck = false;

              //Redirect to /amazon url
              super.ngAfterViewInit();
              window.open(AppLabelConstants.URL_AMAZON_HOME, "_self");
            }
          }
          if (loadCheck) {
            this.getClaimData();
            if (this.storageManagementService.getAppData(SessionConstants.CASE_DATA)) {
              //Start: check for DCS elegibility
              if (this.currUser.sourcePartyDetailsList) {
                this.sourcePartyDetailsList = this.currUser.sourcePartyDetailsList.filter(
                  (res) => res.srcSystemCd === AppConstants.SRC_SYS_CD_2
                ); //DCS Eligibility claims
              }
              //End: check for DCS elegibility
              if (this.sourcePartyDetailsList && this.sourcePartyDetailsList.length > 0) {
                this.isLeaveAssociatedWithDefaultCaseId();
              }
            }
          }
        }
        this.checkDefaultCase();
      },
      (err) => {}
    );
    this.storageManagementService.setSessionData(
      SessionConstants.PREVIOUS_URL,
      AppLabelConstants.URL_HOME
    );
  }

  getClaimData(): void {
    this.loadUserClaims();
    this.displayClaimButton();
    /* Leave Form Library */
    this.employeeToggle();
    const hasTHAAPersoneId = JSON.parse(
      this.storageManagementService.retrieveSessionObject(SessionConstants.APP_DATA)
    ).appData.personIdsInMudi?.length;
    if (hasTHAAPersoneId) {
      this.getFMLIDetails();
      this.isNonLoaCalcDisabled(
        this.storageManagementService.getSessionData(SessionConstants.TOGGLE_LIST)
      );
    }
    this.productFormToggle();
    this.logonStep3();
    /*
     *  Leave Form Library
     * */
  }

  getActivePersonID(e: Event): void {
    const activePersoneIds: string[] = JSON.parse(
      this.storageManagementService.retrieveSessionObject(SessionConstants.APP_DATA)
    )?.appData?.personIdsInMudi;
    let activeUserIds = [];
    let ciiFeatureData;
    let personId;
    let isEmployeeApiFailed = false;
    this.employeeStatusSubscription = this.employeeStatusService
      ?.getEmployeeActiveStatus(activePersoneIds)
      .pipe(
        map((userDetails) => {
          if (userDetails && userDetails.responseCode === "001") {
            if (userDetails.flag) {
              activeUserIds = activePersoneIds?.filter(
                (x) => x === userDetails?.status[0]?.personID
              );
              this.storageManagementService.setAppData(
                SessionConstants.ACTIVE_USER_ID,
                activeUserIds[0]
              );
              this.storageManagementService.setSessionData(
                SessionConstants.PERSON_ID,
                activeUserIds[0]
              );
              personId = this.storageManagementService.getAppData(SessionConstants.USER_DATA)
                .personIds[0];
              ciiFeatureData = this.storageManagementService.getSessionData(
                SessionConstants.CII_FEATURE_DATA_FOR_ACTIVE_PERSON_ID
              );
            } else {
              isEmployeeApiFailed = true;
            }
          } else {
            isEmployeeApiFailed = true;
          }
          return userDetails;
        }),
        mergeMap((resp) =>
          personId !== activeUserIds[0] && !ciiFeatureData && !isEmployeeApiFailed
            ? this.ciiFeature.featureServiceCall(activeUserIds[0])
            : of({})
        )
      )
      .subscribe(
        (featureResponse) => {
          if (isEmployeeApiFailed) {
            this.resolve();
          } else {
            if (featureResponse && featureResponse?.responseCode === "001") {
              this.storageManagementService.setSessionData(
                SessionConstants.CII_FEATURE_DATA_FOR_ACTIVE_PERSON_ID,
                featureResponse
              );
            }
            this.startNewClaim(e);
          }
        },
        (error) => {
          isEmployeeApiFailed = true;
          this.resolve();
        }
      );
  }

  checkType(): boolean {
    if (typeof this.preferredNameExists === "boolean") {
      return true;
    } else {
      return false;
    }
  }

  getPreferredName(): void {
    const personId = this.storageManagementService.getAppData(SessionConstants.USER_DATA)
      ? this.storageManagementService.getAppData(SessionConstants.USER_DATA).personIds[0]
      : null;
    if (personId) {
      this.preferredNameSubscription = this.personService
        .getPreferredName(personId)
        .subscribe((val) => {
          if (val && val.preferredName) {
            this.preferredNameExists = true;
            this.preferredName = val.preferredName;
          } else {
            this.preferredNameExists = false;
          }
        });
    }
  }
  /**
   * @desc this function is used to show/hide the start a new claim button
   * When all the records in sourcepartylist havs activeCase as false we need to hide this button
   * else always display it
   * This function was added as part of provisioning US33380
   */
  displayClaimButton() {
    this.isMBActive = true;
    if (
      this.jsonBOM.userData.sourcePartyDetailsList &&
      this.jsonBOM.userData.sourcePartyDetailsList.length > 0
    ) {
      const filteredList = this.jsonBOM.userData.sourcePartyDetailsList.filter(
        (res) => res.activeCase === true
      );
      if (filteredList.length === 0) {
        // which means all records are having activeCase as false
        this.isMBActive = false;
      }
    }
  }

  logonStep3() {
    /* System Downtime */
    this.errorHandlerService.getSysDownTime(false, AppLabelConstants.HOME_TEXT).subscribe(
      (val) => {
        this.getSysDownMsg = val;
        if (
          this.getSysDownMsg &&
          this.getSysDownMsg.message &&
          this.getSysDownMsg.message.length > 0
        ) {
          const messageType = this.getSysDownMsg.message[0].message_type.toString().toLowerCase();
          const messageText = this.getSysDownMsg.message[0].value;
          const messageCloseType =
            this.getSysDownMsg.message[0].message_close_type.toString().toLowerCase() === "auto"
              ? "true"
              : null;
          const messageHeader =
            messageType === ErrorConstants.ERROR
              ? AppLabelConstants.DANGER_LG
              : messageType === AppLabelConstants.WARNING_SM
              ? AppLabelConstants.WARNING_LG
              : messageType === AppLabelConstants.SUCCESS_SM
              ? AppLabelConstants.SUCCESS_LG
              : AppLabelConstants.INFORMATION_LG;
          this.overviewSysDownKey = this.messageRibbonService.addMessage(
            messageType,
            messageText,
            messageHeader,
            messageCloseType
          );
          this.errorHandlerService.setSysDownKeyOverview(this.overviewSysDownKey);
        }
      },
      (error) => {}
    );

    /*
     * When the service call getDisabilityClaimRelatedContact in Claim-Representative-information completed
     * then only load the post-login-product component to make sure disability related contact retrieved to display in
     * side the description of the product
     * */
    this.contactService.isDisabilityContactCompleted.subscribe((data) => {
      if (data) {
        this.isDisabilityContactCompleted = true;
        //this.changeDetector.detectChanges();
      }
    });
    /* Leave Request */
    this.getLeaveRequestDetails();

    /* Pulse Information */
    this.getWeakPulse();
    this.isPulseRecordPresent();

    //comenting this call as same service is been called in loadUserClaims method
    //this.contactNumber(this.currUser.insuredId);
  }

  ngAfterContentInit(): void {}

  checkDefaultCase() {
    const defaultCaseId =
      this.jsonBOM && this.jsonBOM.caseData && this.jsonBOM.caseData.defaultCaseId
        ? this.jsonBOM.caseData.defaultCaseId
        : "";
    if (this.currUser && this.currUser.sourcePartyDetailsList) {
      this.sourcePartyDetailsList = this.currUser.sourcePartyDetailsList.filter(
        (res) =>
          res.srcPartyName.toUpperCase() === AppConstants.SRC_PARTY_NAME_THAA &&
          res.srcSystemCd === AppConstants.SRC_SYS_CD_8
      ); //Check for default case
      if (this.sourcePartyDetailsList.length > 0) {
        this.storageManagementService.setSessionData(
          SessionConstants.DEFAULT_CASE,
          AppConstants.SRC_PARTY_NAME_THAA
        );
        this.storageManagementService.setSessionData(
          SessionConstants.SOURCE_SYSTEM_CD,
          this.sourcePartyDetailsList[0].srcSystemCd
        );
        this.isDefaultCaseThaa = true;
      } else {
        this.storageManagementService.setSessionData(
          SessionConstants.DEFAULT_CASE,
          AppConstants.SOURCE_SYS_ID_DCS
        );
        this.storageManagementService.setSessionData(
          SessionConstants.SOURCE_SYSTEM_CD,
          AppConstants.SRC_SYS_CD_2
        );
        this.isDefaultCaseThaa = false;
      }
    }
  }

  loadUserClaims() {
    const claims = this.storageManagementService.getAppData(SessionConstants.USER_CLAIMS);
    // const reloadFlg = this.storageManagementService.getAppData(SessionConstants.RELOADCLAIMS);
    /*Change applied to call v1/claims api on every page-load* */
    const reloadFlg = true;
    this.referenceHelper.setReferenceOption("isUserclaim", "false");
    if (claims && Object.keys(claims).length > 0 && !reloadFlg) {
      this.updateClaimLabel(claims);
      this.insuredClaims_nonRelatedClaims = claims.claimOverview.nonRelatedClaims;
      this.insuredClaims_RelatedClaims = claims.claimOverview.relatedClaims;
      this.insuredLeaves = claims.claimOverview.leaveClaims;
      this.userClaims = this.claimService.populateUserClaims(
        this.insuredClaims_RelatedClaims,
        this.insuredClaims_nonRelatedClaims,
        this.insuredLeaves
      );
      if (this.userClaims) {
        this.referenceHelper.setReferenceOption("isUserclaim", "true");
      }
      this.isPromptDisplay = this.storageManagementService.getSessionData("isPromptDisplay");
      this.isClaimExist = true;
      this.jsonBOM.userClaims = claims;
      this.claimsReturned = true;
      this.preRendering = "skeleton-container removed";
      this.loadHelpWidget = true;
      this.areAllClaimsLeaveless = this.selfLinkingService.areAllClaimsLeaveless();
      this.getClaimHistoryInformation(claims.claimHistory);
      this.storageManagementService.setIsUserClaimsSetFlag(true);
      this.paymentHistoryService.isGetBankingResponseAvlbl.next(true);
      this.paymentHistoryService.setProvisions(parseInt(this.currUser.caseId, 10));
      this.paymentHistoryService.setProvisionsForChannelId(parseInt(this.currUser.caseId, 10));
      super.ngAfterViewInit();
    } else {
      //Synchronous call
      this.getClaimInformation(); //General Claims
    }

    this.continueclicked = this.storageManagementService.getSessionData(
      SessionConstants.CONTINUE_CLICKED
    );
    this.IfSiteWideisOutdated();
    this.checkTextTCOutdated(this.currUser);
    if (
      this.currUser &&
      this.currUser.sourcePartyDetailsList &&
      this.currUser.sourcePartyDetailsList.length > 0 &&
      this.currUser.sourcePartyDetailsList[0].srcSystemCd !== 8
    ) {
      this.getUserProfile();
    }
    this.getCIIFeatureData(this.currUser);

    if (
      this.claimsReturned &&
      this.insuredClaims_nonRelatedClaims &&
      this.insuredClaims_nonRelatedClaims?.length === 0 &&
      this.insuredClaims_RelatedClaims &&
      this.insuredClaims_RelatedClaims.length === 0 &&
      this.insuredLeaves &&
      this.insuredLeaves.length === 0
    ) {
      this.noOpenClaims = true;
    }
  }

  // FLI and MLI Changes
  updateClaimLabel(data) {
    this.fmliStatus = this.storageManagementService.getSessionData(SessionConstants.FMLI_STATUS);
    const owcsDataFromSession = this.storageManagementService.getSessionData(
      SessionConstants.OWCS_CONTENT
    );
    for (let i = 0; i < data.claimOverview.nonRelatedClaims.length; i++) {
      if (
        this.fmliStatus != null &&
        this.fmliStatus.fmliPflStatus != null &&
        this.fmliStatus.fmliPflStatus &&
        data != null &&
        data.claimOverview != null &&
        data.claimOverview.nonRelatedClaims != null &&
        data.claimOverview.nonRelatedClaims.length > 0 &&
        data.claimOverview.nonRelatedClaims[i].claimSubType != null &&
        data.claimOverview.nonRelatedClaims[i].claimSubType === "State Paid Family Leave" &&
        owcsDataFromSession != null &&
        owcsDataFromSession.CD_03A_Body != null &&
        owcsDataFromSession.CD_03A_Body.value != null
      ) {
        data.claimOverview.nonRelatedClaims[i].claimSubType = owcsDataFromSession.CD_03A_Body.value;
      }
    }
    for (let i = 0; i < data.claimOverview.relatedClaims.length; i++) {
      if (
        this.fmliStatus != null &&
        this.fmliStatus.fmliStatStatus != null &&
        this.fmliStatus.fmliStatStatus &&
        data != null &&
        data.claimOverview != null &&
        data.claimOverview.relatedClaims != null &&
        data.claimOverview.relatedClaims.length > 0 &&
        data.claimOverview.relatedClaims[i].claimSubType != null &&
        (data.claimOverview.relatedClaims[i].claimSubType === "State-Mandated Disability" ||
          data.claimOverview.relatedClaims[i].claimSubType ===
            "State-Mandated Disability/Maternity") &&
        owcsDataFromSession != null &&
        owcsDataFromSession.CD_03B_Body != null &&
        owcsDataFromSession.CD_03B_Body.value != null
      ) {
        data.claimOverview.relatedClaims[i].claimSubType = owcsDataFromSession.CD_03B_Body.value;
      }
    }
  }

  //calling claims orch service
  getClaimInformation() {
    this.referenceHelper.setReferenceOption("isUserclaim", "false");
    if (
      (this.currUser.insuredId && this.currUser.insuredId.length > 0) ||
      (this.currUser.personIds && this.currUser.personIds.length > 0)
    ) {
      this.claimService.getClaimDetails(this.currUser).subscribe(
        (data) => {
          this.sessionManagement.initDataFetch();
          this.updateClaimLabel(data);
          this.storageManagementService.setAppData(SessionConstants.USER_CLAIMS, data);
          this.storageManagementService.setSessionData(
            SessionConstants.PERSON_ID,
            this.currUser.personIds[0]
          );
          if (data && data.claimHistory) {
            this.getClaimHistoryInformation(data.claimHistory);
          }
          this.insuredClaims_nonRelatedClaims = data.claimOverview.nonRelatedClaims;
          this.insuredClaims_RelatedClaims = data.claimOverview.relatedClaims;
          this.insuredLeaves = data.claimOverview.leaveClaims;
          this.userClaims = this.claimService.populateUserClaims(
            this.insuredClaims_RelatedClaims,
            this.insuredClaims_nonRelatedClaims,
            this.insuredLeaves
          );
          if (this.userClaims) {
            this.referenceHelper.setReferenceOption("isUserclaim", "true");
          }
          this.areLeavesLinkedToSTD();
          this.isPromptIndicatorDisplay();
          this.isClaimExist = true;
          this.jsonBOM.userClaims = data;
          if (this.userClaims && this.userClaims.length > 0) {
            this.paymentHistoryService
              .setupClaimantPaymentInfo(this.userClaims, this.currUser.caseId, false)
              .subscribe((claimantPaymentInfo: ClaimantPaymentInfo) => {
                if (claimantPaymentInfo) {
                  this.paymentHistoryService.saveClaimantPaymentInfoInSession(claimantPaymentInfo);
                  if (!claimantPaymentInfo.isDefaultProvisionsSet) {
                    this.paymentHistoryService.setProvisions(parseInt(this.currUser.caseId, 10));
                    this.paymentHistoryService.setProvisionsForChannelId(
                      parseInt(this.currUser.caseId, 10)
                    );
                  }
                }
              });

            this.selfLinkingService
              .setupClaimsLeaveData(this.userClaims)
              .subscribe((appLeaveInfo: AppLeaveInfo) => {
                this.storageManagementService.setSessionData(
                  SessionConstants.APP_LEAVE_INFO,
                  appLeaveInfo
                );
                this.selfLinkingService.isAppLeaveDataSetInSession.next(true);
                this.areAllClaimsLeaveless = this.selfLinkingService.areAllClaimsLeaveless();
              });
          } else {
            this.paymentHistoryService.setProvisions(parseInt(this.currUser.caseId, 10));
            this.paymentHistoryService.setProvisionsForChannelId(
              parseInt(this.currUser.caseId, 10)
            );
          }
          this.claimsReturned = true;
          //this.getClaimStartAndHistory();
          this.preRendering = "skeleton-container removed";
          if (
            this.claimsReturned &&
            this.insuredClaims_nonRelatedClaims &&
            this.insuredClaims_nonRelatedClaims?.length === 0 &&
            this.insuredClaims_RelatedClaims &&
            this.insuredClaims_RelatedClaims.length === 0 &&
            this.insuredLeaves &&
            this.insuredLeaves.length === 0
          ) {
            this.noOpenClaims = true;
          }
          super.ngAfterViewInit();
        },
        (err) => {
          this.storageManagementService.setIsUserClaimsSetFlag(true);
          this.paymentHistoryService.setProvisions(parseInt(this.currUser.caseId, 10));
          this.paymentHistoryService.setProvisionsForChannelId(parseInt(this.currUser.caseId, 10));
          this.preRendering = "skeleton-container removed";
          super.ngAfterViewInit();
          this.claimsReturned = true;
          this.insuredClaims_nonRelatedClaims = [];
          this.insuredClaims_RelatedClaims = [];
          this.insuredLeaves = [];
          if (
            this.claimsReturned &&
            this.insuredClaims_nonRelatedClaims &&
            this.insuredClaims_nonRelatedClaims?.length === 0 &&
            this.insuredClaims_RelatedClaims &&
            this.insuredClaims_RelatedClaims.length === 0 &&
            this.insuredLeaves &&
            this.insuredLeaves.length === 0
          ) {
            this.noOpenClaims = true;
          }
          this.loadHelpWidget = true;
        },
        () => {
          this.loadHelpWidget = true;
        }
      );
    } else {
      //Use case for when no insured id is found
      this.storageManagementService.setIsUserClaimsSetFlag(true);
      //User needs a claim to be able to use Direct Deposit, no insuredId === co claim so call below is disabled
      //Still requires to set Provisions in APPJSON so that Blaze service utilizes the provisions response to determine which FAQs to be shown
      this.paymentHistoryService.setProvisions(parseInt(this.currUser.caseId, 10));
      this.paymentHistoryService.setProvisionsForChannelId(parseInt(this.currUser.caseId, 10));
      this.insuredClaims_nonRelatedClaims = [];
      this.insuredClaims_RelatedClaims = [];
      this.insuredLeaves = [];
      this.claimsReturned = true;
      //this.getClaimStartAndHistory();
      this.loadHelpWidget = true;
      this.preRendering = "skeleton-container removed";
      if (
        this.claimsReturned &&
        this.insuredClaims_nonRelatedClaims &&
        this.insuredClaims_nonRelatedClaims.length === 0 &&
        this.insuredClaims_RelatedClaims &&
        this.insuredClaims_RelatedClaims.length === 0 &&
        this.insuredLeaves &&
        this.insuredLeaves.length === 0
      ) {
        this.noOpenClaims = true;
      }
      super.ngAfterViewInit();
    }
  }

  // This function is used to determins if a leave is concurrent to a STD claim.
  areLeavesLinkedToSTD() {
    const claimHistory = this.storageManagementService.getSessionData(
      SessionConstants.IS_CLAIM_HISTORY_EXIST
    )
      ? JSON.parse(
          this.storageManagementService.retrieveSessionObject(SessionConstants.CLAIM_HISTORY)
        ).claimHistory
      : null;
    const historyClaim =
      claimHistory && claimHistory.disabilityClaims ? claimHistory.disabilityClaims : [];
    const historyLeave = claimHistory && claimHistory.leaveClaims ? claimHistory.leaveClaims : [];
    const userClaimList = [
      ...this.insuredClaims_RelatedClaims,
      ...this.insuredClaims_nonRelatedClaims,
      ...historyClaim,
    ];

    // for claim overview
    for (let entry of this.insuredLeaves) {
      entry = this.isSTDLinked(entry, userClaimList);
    }
    // for claim history
    for (let entry of historyLeave) {
      entry = this.isSTDLinked(entry, userClaimList);
    }
    // to set modifies values in appData
    const claimsResponse = this.storageManagementService.getAppData(SessionConstants.USER_CLAIMS);
    claimsResponse.claimOverview.leaveClaims = this.insuredLeaves;
    claimsResponse.claimHistory.leaveClaims = historyLeave;
    this.storageManagementService.setAppData(SessionConstants.USER_CLAIMS, claimsResponse);

    // to set modifies values in claimHistory object in session
    if (claimHistory) {
      claimHistory.leaveClaims = historyLeave;
      this.storageManagementService.setSessionObject(SessionConstants.CLAIM_HISTORY, claimHistory);
    }
  }
  // This function is used to determins if a leave is concurrent to a STD claim.
  isSTDLinked(entry, userClaimList) {
    const data = userClaimList.filter(
      (claim) =>
        claim.coverageCategory.toUpperCase() === AppConstants.WD &&
        claim.concurrentLeaveIDs &&
        claim.concurrentLeaveIDs.indexOf(entry.leaveId) >= 0
    );
    entry.linkedSTD = false;
    if (data.length > 0) {
      entry.linkedSTD = true;
    }
    return entry;
  }

  getClaimHistoryInformation(claimsHistory) {
    this.isClaimHistoryApplicable = this.claimService.isClaimsHistoryApplicable(claimsHistory);
    this.storageManagementService.setSessionData(
      SessionConstants.IS_CLAIM_HISTORY_EXIST,
      this.isClaimHistoryApplicable
    );
    if (this.isClaimHistoryApplicable === true) {
      this.storageManagementService.setSessionObject(SessionConstants.CLAIM_HISTORY, claimsHistory);
      this.getPaymentInfo(claimsHistory.disabilityClaims);
      this.paymentHistoryService
        .setupClaimantPaymentInfo(claimsHistory.disabilityClaims, this.currUser.caseId, true)
        .subscribe((historicalClaimantPaymentInfo: ClaimantPaymentInfo) => {
          if (historicalClaimantPaymentInfo) {
            this.paymentHistoryService.saveHistoricalClaimantPaymentInfoInSession(
              historicalClaimantPaymentInfo
            );
          }
        });
    }
  }

  /*
   * call appropriate api for payments depending on claim system
   * */
  getPaymentInfo(historyClaims) {
    let payments = [];
    const paymentsTriggered = this.storageManagementService.getSessionData(
      SessionConstants.PAYMENTS_TRIGGERED_FOR_CLAIMS
    );
    if (paymentsTriggered && paymentsTriggered.length > 0) {
      payments = [...paymentsTriggered];
    }

    if (historyClaims) {
      historyClaims.forEach((claim) => {
        let index = -1;
        if (payments && payments.length > 0) {
          index = payments.findIndex((claimId) => claimId === claim.claimEventId);
        }
        if (index === -1) {
          if (claim.sourceSystemId === AppConstants.SOURCE_SYS_ID_DCS) {
            this.callGetPaymentHistory(claim);
          } else if (claim.sourceSystemId === AppConstants.SOURCE_SYS_ID_AA) {
            this.callGetAetnaPaymentHistory(claim);
          }
          payments.push(claim.claimEventId);
          this.storageManagementService.setSessionData(
            SessionConstants.PAYMENTS_TRIGGERED_FOR_CLAIMS,
            payments
          );
        }
      });
    }
  }

  /*
   * api call to fetch payment details for DCS
   * */
  callGetPaymentHistory(claim) {
    const claimantDetails = this.storageManagementService.getAppData(SessionConstants.USER_DATA);
    this.claimPaymentRequest.insuredId = claimantDetails.srcSysId;
    this.claimPaymentRequest.claimEventId = claim.claimEventId;
    this.dcsPaymentSubscription = this.paymentHistoryService
      .getClaimPaymentHistory(this.claimPaymentRequest)
      .subscribe(
        (val) => {
          if (val) {
            const claimPaymentHistoryResponse = val;
            if (claimPaymentHistoryResponse.claimPaymentInfo) {
              const payment = claimPaymentHistoryResponse.claimPaymentInfo;
              this.paymentHistoryService.setClaimPaymentInfo(claim.claimEventId, payment);
              const claimType = this.paymentHistoryService.dataExists(claim.claimType);
              const claimSubType = this.paymentHistoryService.dataExists(claim.claimSubType);
              payment.forEach((paymentMade) => {
                this.paymentHistoryService.paymentsSortedByIssueDate(
                  claim.claimEventId,
                  claimType,
                  claimSubType,
                  AppConstants.SOURCE_SYS_ID_DCS,
                  "",
                  paymentMade,
                  null,
                  this.claimPaymentRequest.insuredId
                );
              });
              this.paymentHistoryService.setClaimPaymentDetails(
                claim.claimEventId,
                claimPaymentHistoryResponse.claimPaymentInfo,
                AppConstants.SOURCE_SYS_ID_DCS
              );
            } else {
              this.paymentHistoryService.setClaimPaymentInfo(claim.claimEventId, []);
            }
            const totalPaymentsData = this.storageManagementService.getSessionData(
              AppLabelConstants.PAYMENTS_LIST_DCS
            );
            let totalPayementClaimResponseArr = [];
            if (totalPaymentsData) {
              totalPayementClaimResponseArr = totalPaymentsData;
            } else {
              totalPayementClaimResponseArr = [];
            }
            const totalPayementClaimResponse: ItotalPayments = {
              claimEventId: claim.claimEventId,
              totalPaymentResponseData: claimPaymentHistoryResponse,
            };
            if (
              !totalPayementClaimResponseArr.some(
                (element) => element.claimEventId === claim.claimEventId
              )
            ) {
              totalPayementClaimResponseArr.push(totalPayementClaimResponse);
            }
            this.storageManagementService.setSessionData(
              AppLabelConstants.PAYMENTS_LIST_DCS,
              totalPayementClaimResponseArr
            );
          }
        },
        (error) => {
          const totalPaymentsData = this.storageManagementService.getSessionData(
            AppLabelConstants.PAYMENTS_LIST_DCS
          );
          let totalPayementClaimResponseArr = [];
          if (totalPaymentsData) {
            totalPayementClaimResponseArr = totalPaymentsData;
          } else {
            totalPayementClaimResponseArr = [];
          }
          const totalPayementClaimResponse: ItotalPayments = {
            claimEventId: claim.claimEventId,
            totalPaymentResponseData: error,
          };
          if (
            !totalPayementClaimResponseArr.some(
              (element) => element.claimEventId === claim.claimEventId
            )
          ) {
            totalPayementClaimResponseArr.push(totalPayementClaimResponse);
          }
          this.storageManagementService.setSessionData(
            AppLabelConstants.PAYMENTS_LIST_DCS,
            totalPayementClaimResponseArr
          );
        }
      );
  }

  /*
   * api call to fetch payment details for thaa
   * */
  callGetAetnaPaymentHistory(claim) {
    this.aaPaymentSubscription = this.aetnaPaymentsService
      .getAetnaDisabilityClaimPayments(claim.personId, claim.claimEventId)
      .subscribe((val) => {
        if (val) {
          const aaClaimPaymentResponse = val;
          if (
            aaClaimPaymentResponse &&
            aaClaimPaymentResponse.paymentList &&
            aaClaimPaymentResponse.paymentList.length > 0
          ) {
            const claimType = this.paymentHistoryService.dataExists(claim.claimType);
            const claimSubType = this.paymentHistoryService.dataExists(claim.claimSubType);
            aaClaimPaymentResponse.paymentList.forEach((payment) => {
              this.paymentHistoryService.paymentsSortedByIssueDate(
                claim.claimEventId,
                claimType,
                claimSubType,
                AppConstants.SRC_PARTY_NAME_THAA,
                "",
                payment,
                claim.personId,
                null
              );
            });
            this.paymentHistoryService.updateClaimPaymentSession(
              aaClaimPaymentResponse,
              claim.claimEventId,
              claim.personId,
              claimType,
              claimSubType
            );
            this.storageManagementService.setSessionData(
              SessionConstants.DISPLAY_PAY_STUB,
              aaClaimPaymentResponse.displayPaystub
            );
          } else {
            this.paymentHistoryService.setClaimPaymentInfo(claim.claimEventId, []);
          }
        }
      });
  }

  /*request leave section
   * Method Name  : getLeaveRequestDetails();
   * Purpose : This method is used to check leqve request display or not base on condition
   * Created Date : 1/2/2018
   * Modified DATE : 14/3/2018
   * Modification Purpose : To remove the tostring from boolean values
   * Created by: AC12256
   * Modified by : AC12256
   * Hand over to :
   * */
  getLeaveRequestDetails() {
    if (this.jsonBOM.userData.sourcePartyDetailsList) {
      for (let i = 0; i < this.jsonBOM.userData.sourcePartyDetailsList.length; i++) {
        if (
          this.jsonBOM.userData.sourcePartyDetailsList[i].srcSystemCd === AppConstants.SRC_SYS_CD_2
        ) {
          //DCS Eligibility
          this.isLeaveRequestDisplay = true;
          this.isELGweak = false;

          if (
            this.jsonBOM.userData.sourcePartyDetailsList[i].linkTypeCd ===
            AppConstants.LINK_TYPE_CD_2
          ) {
            //weak DCS Eligibility for self linking
            this.isELGweak = true;
            this.storageManagementService.setSessionData(
              SessionConstants.ELIGIBILITY_ID,
              this.jsonBOM.userData.sourcePartyDetailsList[i].srcSysId.toString()
            );
          }
          this.storageManagementService.setSessionData(
            SessionConstants.IS_LEAVE_REQUEST_DISPLAY,
            this.isLeaveRequestDisplay
          );
          this.storageManagementService.setSessionData(SessionConstants.IS_EL_WEAK, this.isELGweak);
          return;
        }
      }
    } else {
      this.isLeaveRequestDisplay = false;
      this.isELGweak = false;
      this.storageManagementService.setSessionData(
        SessionConstants.IS_LEAVE_REQUEST_DISPLAY,
        this.isLeaveRequestDisplay
      );
      this.storageManagementService.setSessionData(SessionConstants.IS_EL_WEAK, this.isELGweak);
    }
  }

  /** Old Code below. Kept but unused to show what has been changed */
  checkTextTCOutdated(user: User) {
    this.personService.checkIfTextTCOutdated(user.partyRefId).subscribe(
      (val) => {
        const textTCOutdated = val;
        let outdate = false;
        if (textTCOutdated["statusCode"] === "0") {
          outdate = true;
          this.storageManagementService.setSessionData(SessionConstants.TEXT_TC_OUTDATED, true);
        } else {
          this.storageManagementService.setSessionData(SessionConstants.TEXT_TC_OUTDATED, false);
        }
        if ((this.activeInd && outdate) || this.siteWideOutdated) {
          this.activeIndicator = 1;
          this.modalService.openModal(this.tncModal);
        }
      },
      (error) => {
        if (this.siteWideOutdated) {
          this.modalService.openModal(this.tncModal);
        }
      },
      () => {}
    );
  }

  getState() {
    return this.authStateService.getLoginState();
  }

  IfSiteWideisOutdated() {
    if (
      this.jsonBOM &&
      this.jsonBOM.userData &&
      this.jsonBOM.userData &&
      this.jsonBOM.userData.termsAndConditionsExpiredFlag !== undefined &&
      this.jsonBOM.userData.termsAndConditionsExpiredFlag === 0
    ) {
      this.siteWideOutdated = true;
      this.storageManagementService.setSessionData(SessionConstants.DOC_OUTDATED, true);
    } else {
      this.storageManagementService.setSessionData(SessionConstants.DOC_OUTDATED, false);
    }
  }

  //Econsent service to check active indiactor
  getUserProfile() {
    const profileDtls = this.storageManagementService.getAppData(SessionConstants.PROFILE_DETAILS);
    this.storageManagementService.setSessionData(SessionConstants.ACTIVE_INDICATOR, false);
    if (profileDtls && Object.keys(profileDtls).length > 0) {
      this.profileInfo = profileDtls;
      if (
        this.profileInfo &&
        this.profileInfo.econsentDataResponseBeanList &&
        JSON.stringify(this.profileInfo.econsentDataResponseBeanList[0]) !== "{}" &&
        this.profileInfo.econsentDataResponseBeanList.length > 0 &&
        this.profileInfo.econsentDataResponseBeanList[0].econsentCustomerList &&
        this.profileInfo.econsentDataResponseBeanList[0].econsentCustomerList.length > 0
      ) {
        let docCategoryList =
          this.profileInfo.econsentDataResponseBeanList[0].econsentCustomerList[0]
            .eConsentDocumentCategoryList;
        if (docCategoryList) {
          docCategoryList = docCategoryList.filter((data) => {
            if (
              (data.documentCategoryCode === AppConstants.DCST &&
                data.deliveryMethod === AppConstants.SMS &&
                data.activeIndicator === AppConstants.ACTIVE_INDICATOR_1) ||
              (data.documentCategoryCode === AppConstants.DCPT &&
                data.deliveryMethod === AppConstants.SMS &&
                data.activeIndicator === AppConstants.ACTIVE_INDICATOR_1)
            ) {
              return true;
            }
          });
        }
        if (docCategoryList && docCategoryList.length > 0) {
          this.storageManagementService.setSessionData(SessionConstants.ACTIVE_INDICATOR, true);
          this.activeInd = true;
        }
      }
    } else {
      this.storageManagementService.setSessionData(SessionConstants.ACTIVE_INDICATOR, false);
      this.profileService
        .getUserProfile(
          "4",
          this.currUser.insuredId && this.currUser.insuredId.length > 0
            ? this.currUser.insuredId
            : this.currUser.eligibilityId,
          this.currUser.caseId
        )
        .subscribe(
          (data) => {
            if (data) {
              this.storageManagementService.setAppData(SessionConstants.PROFILE_DETAILS, data);
              this.profileInfo = data;
            }
          },
          (err) => {},
          () => {
            if (
              !(
                this.storageManagementService.getAppData(SessionConstants.CASE_DATA) &&
                this.storageManagementService.getAppData(SessionConstants.CASE_DATA)
                  .ssoOnlyCaseIndicator === AppConstants.Y
              ) &&
              this.profileInfo &&
              this.profileInfo.econsentDataResponseBeanList &&
              JSON.stringify(this.profileInfo.econsentDataResponseBeanList[0]) !== "{}" &&
              this.profileInfo.econsentDataResponseBeanList.length > 0
            ) {
              let docCategoryList =
                this.profileInfo.econsentDataResponseBeanList[0].econsentCustomerList[0]
                  .eConsentDocumentCategoryList;
              docCategoryList = docCategoryList.filter((data) => {
                if (
                  (data.documentCategoryCode === AppConstants.DCST &&
                    data.deliveryMethod === AppConstants.SMS &&
                    data.activeIndicator === AppConstants.ACTIVE_INDICATOR_1) ||
                  (data.documentCategoryCode === AppConstants.DCPT &&
                    data.deliveryMethod === AppConstants.SMS &&
                    data.activeIndicator === AppConstants.ACTIVE_INDICATOR_1)
                ) {
                  return true;
                }
              });
              if (docCategoryList && docCategoryList.length > 0) {
                this.storageManagementService.setSessionData(
                  SessionConstants.ACTIVE_INDICATOR,
                  true
                );
                this.activeInd = true;
              }
            }
          }
        );
    }
  }

  isPulseRecordPresent() {
    this.phaOverview.isPulseRecordPresent(this.currUser.caseId, this.currUser.partyRefId).subscribe(
      (phaRecordAvailability: PHARecordsAvailability) => {
        const doesPulseRecordExist = phaRecordAvailability.recordExist;
        if (doesPulseRecordExist) {
          this.isPulseRecordExists = true;
        }
      },
      (error) => {},
      () => {}
    );
  }

  getWeakPulse() {
    this.storageManagementService.setSessionData(
      SessionConstants.SELF_LINKING_PATH,
      AppLabelConstants.URL_HOME
    );
    if (this.jsonBOM.userData.sourcePartyDetailsList) {
      for (let i = 0; i < this.jsonBOM.userData.sourcePartyDetailsList.length; i++) {
        if (
          this.jsonBOM.userData.sourcePartyDetailsList[i].srcSystemCd ===
            AppConstants.SRC_SYS_CD_3 &&
          this.jsonBOM.userData.sourcePartyDetailsList[i].linkTypeCd === AppConstants.LINK_TYPE_CD_2
        ) {
          this.isLeaveRequestDisplay = true;
          this.storageManagementService.setSessionData(
            SessionConstants.PULSE_ID,
            this.jsonBOM.userData.sourcePartyDetailsList[i].srcSysId.toString()
          );
        }
      }
    }
  }

  /*
   * US12116 - Claim Wizard
   * Author: DB
   * Added: 03/08/2018
   * Developer Note: using caseid and partyrefid as block-scope variables because
   * class properties of the same purpose are getting values from the wrong fields at the moment of implementation.
   */
  startAClaim(e: Event) {
    let statusToggle = false;
    const toggleList = this.storageManagementService.getSessionData(SessionConstants.TOGGLE_LIST);
    if (toggleList && toggleList.length > 0) {
      const toggleIndex = toggleList.findIndex(
        (toggles) =>
          toggles.toggle.toLowerCase() === IntakeLabelConstants.EMPLOYEE_STATUS.toLowerCase()
      );
      if (toggleIndex > -1) {
        statusToggle = toggleList[toggleIndex].state;
      }
    }
    const isMultiplePersoneId =
      JSON.parse(this.storageManagementService.retrieveSessionObject(SessionConstants.APP_DATA))
        .appData.personIdsInMudi?.length > 1;
    if (isMultiplePersoneId && statusToggle) {
      this.storageManagementService.deleteActivePersonId();
      this.getActivePersonID(e);
    } else {
      this.startNewClaim(e);
    }
  }

  startNewClaim(e: Event): void {
    e.stopPropagation();
    e.preventDefault();
    this.isLoading = true;
    this.disableButton = true;
    const caseId = this.currUser.caseId;
    const partyRefId = this.currUser.partyRefId;
    const staleData = this.storageManagementService.retrieveSessionObject(
      SessionConstants.WIZARD_OPTIONS
    );
    if (staleData) {
      this.storageManagementService.removeSessionObject(SessionConstants.WIZARD_OPTIONS);
    }
    // for THAA user to navigate to leave submit page and for DCS only user it should navigate to claim wizard flow
    const caseData = this.storageManagementService.getAppData(SessionConstants.CASE_DATA);
    const intake = this.storageManagementService.getSessionData(SessionConstants.TOGGLE_LIST);
    const ciiSessionData = this.intakeUtilitiesService.getCIIFeatureData();
    if (ciiSessionData) {
      this.newClaimIntakeAbsenceRights = ciiSessionData.allowEnterAbsence;
    }
    let checkToggle = false;
    if (intake) {
      const toggleIndex = intake.findIndex(
        (toggles) => toggles.toggle === IntakeLabelConstants.NEWINTAKE
      );
      if (toggleIndex > -1) {
        checkToggle = intake[toggleIndex].state;
      }
    }

    if (checkToggle && this.newClaimIntakeAbsenceRights === 1) {
      this.router.navigate([AppLabelConstants.URL_START_CLAIM]);
    } else if (checkToggle && this.newClaimIntakeAbsenceRights !== 1) {
      this.router.navigate([AppLeaveIntakeConstants.CLAIM_SUBMIT_NOT_ALLOW_PAGE]);
    } else if (
      this.currUser &&
      this.currUser.sourcePartyDetailsList &&
      this.currUser.sourcePartyDetailsList[0] &&
      this.currUser.sourcePartyDetailsList[0].srcSystemCd &&
      this.currUser.sourcePartyDetailsList[0].srcSystemCd === AppConstants.SRC_SYS_CD_8
    ) {
      this.router.navigate([AppLabelConstants.URL_SELECT_REASON]);
    } else {
      this.claimWizardService.getOptionsPostAuth(caseId, partyRefId).subscribe(
        (res) => {
          this.processCWResponse(res);
        },
        (err) => {
          this.processCWResponse(err);
        }
      );
    }
  }

  processCWResponse(res) {
    switch (res.status) {
      case 404:
      case AppConstants.STATUS_CODE_404:
        this.messageRibbonService.addMessage(
          ErrorConstants.ERROR,
          ErrorConstants.ERROR_MSG_SOMETHNG_WENT_WRONG,
          ErrorConstants.ERROR_MESSAGE_1,
          AppLabelConstants.MSG_AUTO_CLS_TEXT
        );
        this.isLoading = false;
        this.disableButton = false;
        break;
      case 403:
      case AppConstants.STATUS_CODE_403:
        this.storageManagementService.setSessionObjectNoJSON(
          SessionConstants.WIZARD_ERROR,
          AppLabelConstants.UNABLE_CASE
        );
        this.router.navigateByUrl(AppLabelConstants.URL_CLAIMWIZ_ERR);
        break;
      case 400:
      case AppConstants.STATUS_CODE_400:
        this.messageRibbonService.addMessage(
          ErrorConstants.ERROR,
          ErrorConstants.ERROR_MSG_SOMETHNG_WENT_WRONG,
          ErrorConstants.ERROR_MESSAGE_1,
          AppLabelConstants.MSG_AUTO_CLS_TEXT
        );
        this.isLoading = false;
        this.disableButton = false;
        break;
      default:
        this.storageManagementService.setSessionObject(SessionConstants.WIZARD_OPTIONS, res);
        this.router.navigateByUrl(AppLabelConstants.URL_CLAIMWIZ_QUES);
        break;
    }
  }

  ngOnDestroy() {
    if (this.aaPaymentSubscription) {
      this.aaPaymentSubscription.unsubscribe();
    }
    if (this.dcsPaymentSubscription) {
      this.dcsPaymentSubscription.unsubscribe();
    }
    if (this.preferredNameSubscription) {
      this.preferredNameSubscription.unsubscribe();
    }
    if (this.employeeStatusSubscription) {
      this.employeeStatusSubscription.unsubscribe();
    }
    if (this.paymentFeatureToggleSubscription) {
      this.paymentFeatureToggleSubscription.unsubscribe();
    }

    if (this.taxFormFeatureToggleSubscription) {
      this.taxFormFeatureToggleSubscription.unsubscribe();
    }
    if (this.startANewClaimFeatureToggleSubscription) {
      this.startANewClaimFeatureToggleSubscription.unsubscribe();
    }
    if (this.fileUploadDisabledToggleSubscription) {
      this.fileUploadDisabledToggleSubscription.unsubscribe();
    }
    if (this.fmliStatusSubscription) {
      this.fmliStatusSubscription.unsubscribe();
    }
    if (document["maternityIntakeAbandon"] !== undefined) {
      delete document["maternityIntakeAbandon"];
    }
    if (document["maternityIntakeSuccess"] !== undefined) {
      delete document["maternityIntakeSuccess"];
    }
    if (document[BondingIntakeSessionConstants.BONDING_INTAKE_ABANDON] !== undefined) {
      delete document[BondingIntakeSessionConstants.BONDING_INTAKE_ABANDON];
    }
  }
  /*
   startNewClaim(){
   this.modalService.openModal('newClaim');
   }
   */
  isLeaveAssociatedWithDefaultCaseId() {
    if (this.jsonBOM && this.jsonBOM.caseData && this.jsonBOM.caseData.defaultCaseId) {
      this.notificationService
        .policyAdminLeaveService(this.jsonBOM.caseData.defaultCaseId)
        .subscribe(
          //490827
          (policyAdminLeaveResponse: PolicyAdminLeaveResponse) => {
            // claim realated with leave or not
            if (policyAdminLeaveResponse) {
              for (let i = 0; i < policyAdminLeaveResponse.leaveResponseList.length; i++) {
                if (
                  policyAdminLeaveResponse.leaveResponseList[i].provisionId ===
                    AppConstants.PROVISION_ID_806 &&
                  (policyAdminLeaveResponse.leaveResponseList[i].provisionOptionId ===
                    AppConstants.PROVISION_OPTION_ID_2 ||
                    policyAdminLeaveResponse.leaveResponseList[i].provisionOptionId ===
                      AppConstants.PROVISION_OPTION_ID_3)
                ) {
                  // claim realated with leave or not
                  this.isLeaveRealatedWithDefaultCaseId = true;
                } else {
                  this.isLeaveRealatedWithDefaultCaseId = false;
                }
              }
            } else {
              this.isLeaveRealatedWithDefaultCaseId = false;
            }
          },
          (error) => {
            this.isLeaveRealatedWithDefaultCaseId = false;
          }
        );
    }
  }

  isPromptIndicatorDisplay() {
    if (this.userClaims && this.userClaims.length > 0) {
      for (let i = 0; i < this.userClaims.length; i++) {
        if (
          this.userClaims[i].coverageCategory &&
          (this.userClaims[i].coverageCategory.toUpperCase() === AppConstants.WD ||
            this.userClaims[i].coverageCategory.toUpperCase() === AppConstants.LTD ||
            this.userClaims[i].coverageCategory.toUpperCase() === AppConstants.PFL)
        ) {
          this.isPromptDisplay = true;
          this.storageManagementService.setSessionData("isPromptDisplay", true);
        }
      }
    }
  }

  public navToHistory() {
    this.router.navigate([AppLabelConstants.URL_CLAIM_HISTORY]);
  }

  public trackNavLinks(linkTitle) {
    this.customEventService.trackAccordion(linkTitle);
  }

  public manageNotifications() {
    if (
      !this.storageManagementService.getSessionData(
        SessionConstants.IS_NOTIFICATION_DATA_SET_IN_SESSION
      )
    ) {
      const userData = this.storageManagementService.getAppData(SessionConstants.USER_DATA);
      if (
        userData &&
        userData.sourcePartyDetailsList &&
        userData.sourcePartyDetailsList.length > 0
      ) {
        for (let i = 0; i < userData.sourcePartyDetailsList.length; i++) {
          if (userData.sourcePartyDetailsList[i].srcSystemCd === AppConstants.SRC_SYS_CD_2) {
            this.eligibilityId = userData.sourcePartyDetailsList[i].srcSysId.toString();
          }
        }
      }
      if ((this.currUser.insuredId || this.eligibilityId) && this.currUser.caseId) {
        this.notificationRegistrationService
          .getNotificationsRelatedData(
            this.currUser.insuredId && this.currUser.insuredId.length > 0
              ? this.currUser.insuredId
              : null,
            this.eligibilityId && this.eligibilityId.length > 0 ? this.eligibilityId : null,
            parseInt(this.currUser.caseId, 10)
          )
          .subscribe((notificationDetails: NotificationDetails) => {
            /*   if (notificationDetails && notificationDetails.isNotification &&
                 (notificationDetails.notificationCategory && (notificationDetails.notificationCategory.isPaymentApplicable || notificationDetails.notificationCategory.isStatusApplicable)))*/
            if (notificationDetails) {
              this.storageManagementService.setNotificationsData(notificationDetails);
              this.storageManagementService.setSessionData(
                SessionConstants.IS_NOTIFICATION_DATA_SET_IN_SESSION,
                true
              );
              this.notificationRegistrationService.isNotificationsDataSetInSession.next(true);
            }
          });
      }
    } else {
      this.notificationRegistrationService.isNotificationsDataSetInSession.next(true);
    }
  }

  resetClaimRepresentativeFlag() {
    this.contactService.setIsClaimRepNeedToDisplay(true);
  }

  getFeatureTurnOnOffInfo() {
    const featuresFromSession = this.storageManagementService.getFeaturesData();
    if (!featuresFromSession || (featuresFromSession && featuresFromSession.length === 0)) {
      this.contentService
        .getFeaturesTurnOnOff()
        .subscribe((featureTurnOnOffResponse: FeatureTurnOnOffResponse) => {
          if (
            featureTurnOnOffResponse &&
            featureTurnOnOffResponse.message &&
            featureTurnOnOffResponse.message.length > 0
          ) {
            const features: Feature[] = featureTurnOnOffResponse.message;
            this.storageManagementService.setFeaturesData(features);
            this.storageManagementService.isFeaturesTurnOnOffDataSetInSession.next(true);
          }
        });
    }
  }
  absenceContentFetch() {
    const owcsDataFromSession = this.storageManagementService.getSessionData(
      MagnoliaPageConstants.PLAN_MY_LEAVE_LAUNCH
    );
    if (!owcsDataFromSession) {
      const pageId: string = this.contentService.getPageId(
        MagnoliaPageConstants.PLAN_MY_LEAVE_LAUNCH
      );
      if (pageId) {
        this.contentService
          .getOWCSResponse(pageId, MagnoliaPageConstants.PLAN_MY_LEAVE_LAUNCH)
          .subscribe(
            (val) => {
              if (val && val.data) {
                this.storageManagementService.setSessionData(
                  MagnoliaPageConstants.PLAN_MY_LEAVE_LAUNCH,
                  val.data
                );
              }
            },
            (error) => {
              console.error(error);
            }
          );
      }
    }
  }
  owcsContentFetch() {
    const owcsDataFromSession = this.storageManagementService.getSessionData(
      SessionConstants.OWCS_CONTENT
    );
    if (!owcsDataFromSession) {
      const pageId: string = this.contentService.getPageId(AppConstants.CLAIM_DETAILS);
      if (pageId) {
        this.contentService.getOWCSResponse(pageId, AppConstants.CLAIM_DETAILS_U).subscribe(
          (val) => {
            if (val && val.data) {
              this.storageManagementService.setSessionData(SessionConstants.OWCS_CONTENT, val.data);
            }
          },
          (error) => {
            console.error(error);
          }
        );
      }
    }
  }
  owcsErrorMsgContentFetch() {
    const pageId: string = this.contentService.getPageId(AppConstants.PAGE_NAME_OWCS_ERR_MSG);
    if (pageId) {
      this.contentService.getOWCSResponse(pageId, AppConstants.PAGE_NAME_OWCS_ERR_MSG).subscribe(
        (val) => {
          if (val && val.data) {
            this.storageManagementService.setSessionData(
              SessionConstants.OWCS_ERR_MSG_CONTENT,
              val.data
            );
          }
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }

  owcsErrorMsgContentFetchFP() {
    const pageId: string = this.contentService.getPageId(AppConstants.FAILED_TRANSACTION_ERROR_PG);
    if (pageId) {
      this.contentService
        .getOWCSResponse(pageId, AppConstants.FAILED_TRANSACTION_ERROR_PG_U)
        .subscribe((val) => {
          if (val) {
            this.owcsContent = val;
            if (
              this.owcsContent &&
              this.owcsContent.data &&
              this.owcsContent.data.FTEP_03_Body &&
              this.owcsContent.data.FTEP_02_Body
            ) {
              this.storageManagementService.setSessionData(
                SessionConstants.OWCS_ERR_MSG_CONTENTFP,
                val.data
              );
            }
          }
        });
    }
  }
  initializeObjects() {
    this.jsonBOM.currentPage =
      this.router && this.router.url === AppLabelConstants.URL_HOME
        ? AppLabelConstants.CLAIM_OVERVIEW
        : AppLabelConstants.PRE_LOGIN;
    this.jsonBOM.userData = this.jsonBOM.userData || {};
    this.jsonBOM.paymentDetails = this.jsonBOM.paymentDetails || {};
    this.jsonBOM.provisions = this.jsonBOM.provisions || {};
    this.jsonBOM.coveragesMudi = this.jsonBOM.coveragesMudi || {};
    this.jsonBOM.profileDetails = this.jsonBOM.profileDetails || {};
    this.jsonBOM.userClaims = this.jsonBOM.userClaims || {};
    this.jsonBOM.coveragePlans = this.jsonBOM.coveragePlans || {};
    this.jsonBOM.leaveService = this.jsonBOM.leaveService || {};
    this.jsonBOM.claimPayments = this.jsonBOM.claimPayments || {};
    this.jsonBOM.bankingDetails = this.jsonBOM.bankingDetails || {};
    this.jsonBOM.reloadClaims = this.jsonBOM.reloadClaims || false;
    this.jsonBOM.loginStatus = this.jsonBOM.loginStatus || false;

    this.storageManagementService.setSessionObject(SessionConstants.APP_DATA, this.jsonBOM);
  }

  /**
   * CII service call to show feature need to be shown or not. For every functionality to suppress the service sends a flag
   * with which UI determines whether to enable/disable the feature.
   *
   * @param userData
   */
  getCIIFeatureData(userData) {
    const CIIFeatureData = this.storageManagementService.getSessionData(
      SessionConstants.CII_FEATURE_DATA
    );
    const personID = userData && userData.personIds ? userData.personIds[0] : null;
    if (!CIIFeatureData && personID) {
      this.ciiFeature.featureServiceCall(personID).subscribe((data) => {
        this.storageManagementService.setSessionData(SessionConstants.CII_FEATURE_DATA, data);
        this.overpaymentEligibility(data, personID);
        const directDepositPayStubFlag = data.allowSuppressDirectDepositPayStub === 1;
        this.storageManagementService.setSessionData(
          SessionConstants.DIRECT_DEPOSIT_PAYSTUB_FLAG,
          directDepositPayStubFlag
        );
      });
    }
    if (CIIFeatureData && personID) {
      this.overpaymentEligibility(CIIFeatureData, personID);
    }
  }

  /*
   * call common toggle api and store toggle list in session data
   * */
  employeeToggle() {
    if (
      !this.storageManagementService.getSessionData(SessionConstants.TOGGLE_LIST) &&
      this.currUser &&
      this.currUser.personIds &&
      this.currUser.personIds.length > 0
    ) {
      this.toggle.employeeToggle(this.currUser.personIds[0]).subscribe((res) => {
        if (res && res["responseCode"] === AppConstants.RES_CODE_001 && res["toggleList"]) {
          this.storageManagementService.setSessionData(
            SessionConstants.TOGGLE_LIST,
            res["toggleList"]
          );
          this.isNonLoaCalcDisabled(
            this.storageManagementService.getSessionData(SessionConstants.TOGGLE_LIST)
          );
        }
        this.absencePlanningToggle(
          this.storageManagementService.getSessionData(SessionConstants.TOGGLE_LIST)
        );
      });
    }
    this.absencePlanningToggle(
      this.storageManagementService.getSessionData(SessionConstants.TOGGLE_LIST)
    );
  }

  getFMLIDetails(): void {
    this.fmliStatusSubscription = this.fmliStatusService
      .getFMLIStatus(this.currUser.personIds[0])
      .subscribe();
  }

  public productFormToggle() {
    let isTHAA = false;

    isTHAA =
      this.currUser && this.currUser.personIds && this.currUser.personIds.length > 0 ? true : false;
    this.isLeaveAssociatedWithClaim = false;
    if (isTHAA) {
      this.claimService.getProductFormsToggle(this.currUser).subscribe((res) => {
        this.storageManagementService.setAppData(
          SessionConstants.PRODUCT_FORM_TOGGLE,
          res["productForms"]
        );
        if (res["productForms"]) {
          this.isLeaveAssociatedWithClaim = true;
          this.isToggleOn = true;
        } else if (
          !res["productForms"] &&
          this.jsonBOM &&
          this.jsonBOM.caseData &&
          this.jsonBOM.caseData.hasThaaLeave
        ) {
          this.isLeaveAssociatedWithClaim = true;
        }
      });
    } else if (this.jsonBOM && this.jsonBOM.caseData && this.jsonBOM.caseData.hasReedLeave) {
      this.isLeaveAssociatedWithClaim = true;
    }
  }

  /*
   * check if user is eligible for over payments
   * @param ciiFeatureData: user rights for OverPaymentByCreditCardAccess
   * @param current user: person id
   * */
  overpaymentEligibility(ciiFeatureData, personId) {
    if (ciiFeatureData && ciiFeatureData.OverPaymentByCreditCardAccess === 1) {
      const eligibilitySet = this.storageManagementService.getSessionData(
        SessionConstants.IS_OVERPAYMENT_ELIGIBLE
      );
      if (!eligibilitySet && personId) {
        this.claimService
          .getOverpaymentEligibility(personId)
          .pipe(takeUntil(this._destroy$))
          .subscribe((res) => {
            if (res && res["responseCode"] === AppConstants.RES_CODE_001) {
              const eligibility = res["IsOverpaymentsEligible"];
              this.storageManagementService.setSessionData(
                SessionConstants.IS_OVERPAYMENT_ELIGIBLE,
                eligibility
              );
              this.referenceHelper.setReferenceOption("overpaymentsApplicable", eligibility);
              this.overpaymentApplicable = JSON.parse(eligibility);
            }
          });
      } else if (eligibilitySet) {
        this.overpaymentApplicable = JSON.parse(eligibilitySet);
      }
    }
  }
}
