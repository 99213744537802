import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject, of} from 'rxjs';

@Injectable()
export class ModalService {

  private currentModal: string;

  private modalClosed = new Subject();
  private modalOpenedSource = new BehaviorSubject<string>('cancelModal');
  private flagOpennew: boolean = false;


  modalClosed$ = this.modalClosed.asObservable();
  modalOpened$ = this.modalOpenedSource.asObservable();

  get flagOpen() {
    return this.flagOpennew;
  }
  set flagOpen(flagOpennew) {
    this.flagOpennew = flagOpennew;
  }

  closeModal(modalName: string) {
    this.flagOpennew = false;

    //console.log(' ========== Calling Close Modal =============' + modalName);
    this.modalClosed.next(modalName);

  }

  openModal(modalName: string) {
    //console.log(' ========== Calling Open Modal =============' + modalName);
    this.flagOpennew = true;

    this.currentModal = modalName;
    this.modalOpenedSource.next(modalName);
  }

  getActiveModal() {
    //console.log(' ========== Calling Active Modal =============' );

    return this.currentModal;
  }

  closeActiveModal() {
    //console.log(' ========== Calling close Active Modal =============' + this.getActiveModal());

    this.closeModal(this.currentModal);
  }

  /* Resets the modal subscriptions */
  clear() {
    this.modalOpenedSource.complete();
    this.modalClosed.complete();
    this.modalClosed = new Subject();
    this.modalOpenedSource = new BehaviorSubject('');
    this.modalClosed$ = this.modalClosed.asObservable();
    this.modalOpened$ = this.modalOpenedSource.asObservable();
  }
}
