import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { IHorizontalStepper } from '@app/cxr/common/molecules/horizontal-stepper/horizontal-stepper.model';
import { AppLeaveConstants } from '@app/cxr/leave-details-flow/leave-details.constants';
import {
  IHukAccordian, IHukAccordianDays,
  IHukRadioYesNoOptions,
  INTAKE_OWCS_IDS,
  YES_NO_OBJECT
} from '@app/cxr/modules/intake/shared/JSON/intake-owcs.constants';
import {
  PROGRESS_BAR_STEPS,
  STEPPER_PAGES
} from '@app/cxr/modules/intake/shared/constants/intake-progress-bar-constants';
import { Intake_Constants } from '@app/cxr/modules/intake/shared/constants/intake.constants';
import {
  BondingIntakeSessionConstants,
  IntakeSessionConstants
} from '@app/cxr/modules/intake/shared/constants/intakeSession.constants';
import { IHukDropDownOptions } from '@app/cxr/modules/intake/shared/models/absence-section.model';
import { ClaimLeaveDetailList } from '@app/cxr/modules/intake/shared/models/claim-leave-model';
import { IDayAndMonthFormat } from '@app/cxr/modules/intake/shared/models/date-format.model';
import { IExistingOvertimeContent } from '@app/cxr/modules/intake/shared/models/existing-overtime.model';
import {
  DROPDOWN_CONFIG_TYPE,
  DROPDOWN_TYPE,
  IHoursMinutesDropdownSectionContent,
  IHukHourMinsOnlyObject,
  IHukHoursMinsObject
} from '@app/cxr/modules/intake/shared/models/hours-mins-object.model';
import {
  IMinutesHoursListObject,
  IWorkScheduleListObject,
  LoadWorkScheduleList
} from '@app/cxr/modules/intake/shared/models/intake-work-schedule.model';
import { ISelectedLeaveDetails } from '@app/cxr/modules/intake/shared/models/selected-leave-details.model';
import { AppConstants } from '@app/shared/constants/app.constants';
import { SessionConstants } from '@app/shared/constants/session.constants';
import { StorageManagementService } from '@shared/services/storage-management.service';
import { IntakeLabelConstants } from '../constants/intake-label.constants';
import { IAddExtensionPayload } from '../models/work-schedule-details.model';

@Injectable({
  providedIn: 'root'
})
export class IntakeUtilitiesService {
  utilityConsts = {...IntakeSessionConstants , ...BondingIntakeSessionConstants};
  workScheduleList: IWorkScheduleListObject[] = [];
  readonly stepperPages = STEPPER_PAGES;
  readonly stepNumbers = PROGRESS_BAR_STEPS;
  intakeConstants = IntakeLabelConstants;
  baseStep: string = '';
  appConstants = AppConstants;

  constructor(private storageManagementService: StorageManagementService, private datePipe: DatePipe
  ) { }

  /*
  * get status for specified toggle
  * @param: toggle - toggle to check status for
  * */
  public checkToggleStatus(toggle: string): boolean {
    let toggles = this.storageManagementService.getIntakeSessionData(this.utilityConsts.INTAKE_TOGGLES);
    if (toggles) {
      toggles = JSON.parse(toggles);
      return toggles[toggle.toLowerCase()];
    }
  }

  /**
   * get toggle status for specified toggle name
   *
   * @param toggle
   * @returns
   */
  getToggleState(toggle: string): boolean {
     let state = false;
    const toggleList = this.storageManagementService.getSessionData(SessionConstants.TOGGLE_LIST);
    if (toggleList && toggleList.length > 0) {
      const toggleIndex = toggleList.findIndex(toggles => toggles.toggle.toLowerCase() === toggle.toLowerCase());
      if (toggleIndex > -1) {
        state = (toggleList[toggleIndex].state);
      }
    }
    return state;
  }

  /*
  * fetch specific content from leave
  * @param leaves  - array of leaves
  * @param leaveId - leave id from radio tile, eg. leave_1234567
  * */
  public fetchLeaveData(leaves, leaveId: string) {
    const id = leaveId?.split('_');
    if (id[1]) {
      const leaveData =  leaves.filter(leave => leave.leaveId === id[1]);
      return leaveData[0] || '';
    }
  }

  /*
  * binary search algorithm to find an element within the sorted passed array
  * @param arrayToSearch  - array to loop through and identify the element from
  * @param startInd       - start index of array
  * @param endInd         - end index of array
  * @param target         - item to find in array
  * @param elementInArray - item in the array to compare target with
  * */
  binarySearch(arrayToSearch, startInd: number, endInd: number, target: Date, elementInArray: string): number {
    const startIndex = this.fetchItemFromArray(arrayToSearch, startInd, elementInArray);
    const endIndex = this.fetchItemFromArray(arrayToSearch, endInd, elementInArray);

    if (startIndex === target) {
      return startInd;
    } else if (endIndex === target) {
      return endInd;
    } else {
      while (startInd <= endInd) {
        let midInd = Math.floor((startInd + endInd) / 2);
        const midIndex = this.fetchItemFromArray(arrayToSearch, midInd, elementInArray);

        if (midIndex.toLocaleDateString() === target.toLocaleDateString()) {
          return midInd;
        } else if (midIndex < target) {
          startInd = ++midInd;
        } else {
          endInd = --midInd;
        }
      }
    }
  }

  /*
  * item to fetch dynamically from array at specific index
  * */
  fetchItemFromArray(arrayToSearch: any[], index: number, itemInArray: string) {
    switch (itemInArray) {
      case 'Day':
        return new Date(arrayToSearch[index]?.Day);
      default:
        break;
    }
  }

  removeTag(val: string): string {
    const div = document.createElement('div');
    div.innerHTML = val;
    return div.innerText;
  }

  phoneNumberFormat(phoneNo) {
    if (phoneNo && phoneNo.trim() && phoneNo.length >= 10) {
      const phNoValue = (( phoneNo.slice(0, 2) === '(1') ? phoneNo.slice(1, phoneNo.length) : phoneNo.slice(0, phoneNo.length));
      const phNo = phNoValue.replace(/\D/g, '');
      return phoneNo = '(' + phNo.slice(0, 3) + ')  ' + phNo.slice(3, 6) + '-' + phNo.slice(6, 10);
    } else {
      return phoneNo;
    }
  }

  replaceHtmlTags(value: string): string {
    const replaceDiv = value?.replace(/(\s*<.*?>\s*)+/g, ' ');
    const removeSpace = replaceDiv?.replace(/  +/g, ' ');
    return removeSpace;
  }

  mapRegFeildAndRegValues(registerId: number, regFieldVal: string): IAddExtensionPayload {
    return {
      RegFieldId: registerId,
      RegFieldValue: regFieldVal
    };
  }

  stepSetupForStepper(iconValue: string, routerUrlValue: string, isCompleteValue: boolean, currentStepValue: boolean, idValue: number, activeIconValue: string, inactiveIconValue: string, altTextValue: string): IHorizontalStepper {
    return {
      icon        : iconValue,
      routerUrl   : routerUrlValue,
      isComplete  : isCompleteValue,
      currentStep : currentStepValue,
      id          : idValue,
      activeIcon  : activeIconValue,
      inactiveIcon: inactiveIconValue,
      altText     : altTextValue
    }
  }

   getDateFormat(dateToFormat: string): IDayAndMonthFormat {
    const fdaDateSuffix = new Date(dateToFormat).toDateString();
    const date = new Date(dateToFormat).getDate();
    const nthNumber = () => {
      if (date > 3 && date < 21) {
        return 'th';
      }
      switch (date % 10) {
        case 1:
          return 'st';
        case 2:
          return 'nd';
        case 3:
          return 'rd';
        default:
          return 'th';
      }
    };
    const nthDateValue = nthNumber();
    const dayAndMonth = new Date((dateToFormat))?.toLocaleString("default", { weekday: "long" }) + ', ' + new Date((dateToFormat))?.toLocaleString("default", { month: "long" }) + ' ' + date;
    const dateFormat: IDayAndMonthFormat = {
      dateMonth: dayAndMonth,
      nthDate: nthDateValue
    };
    return dateFormat;
  }

  getRotationDateFormat(dateToFormat: string): IDayAndMonthFormat {
    const fdaDateSuffix = new Date(dateToFormat).toLocaleDateString('en-us', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'});
    const date = new Date(dateToFormat).getDate();
    const nthNumber = () => {
      if (date > 3 && date < 21) {
        return 'th';
      }
      switch (date % 10) {
        case 1:
          return 'st';
        case 2:
          return 'nd';
        case 3:
          return 'rd';
        default:
          return 'th';
      }
    };
    const nthDateValue = nthNumber();
    const dayAndMonth = fdaDateSuffix.split(' ')[0] + ' ' + fdaDateSuffix.split(' ')[1] + ' ' + date;
    const dateFormat: IDayAndMonthFormat = {
      dateMonth: dayAndMonth,
      nthDate: nthDateValue
    };
    return dateFormat;
  }

  getWorkScheduleObj() {
    const workSchedule = this.storageManagementService.getIntakeSessionData(this.utilityConsts.WORK_SCHEDULE);
    if (workSchedule?.LoadWorkScheduleList?.length > 0) {
      return Object.assign({}, ...workSchedule.LoadWorkScheduleList.map(schedule => ({[schedule.Day]: schedule})));
    }
  }

  getWorkScheduleForDate(date: string): IWorkScheduleListObject['workScheduleList'] {
    const formattedDate = new Date(date);
    if (!this.workScheduleList || this.workScheduleList?.length === 0 || !this.workScheduleList[formattedDate?.toLocaleDateString()]) {
      this.workScheduleList = this.getWorkScheduleObj();
    }
    if (this.workScheduleList && formattedDate?.toLocaleDateString('en-US') in this.workScheduleList) {
      return this.workScheduleList[formattedDate?.toLocaleDateString('en-US')];
    }
  }

  resetWorkScheduleList() {
    this.workScheduleList = this.getWorkScheduleObj();
  }

  isNonWorkDay(date: string): boolean {
    const workSchedule = this.getWorkScheduleForDate(date);
    return !workSchedule?.IsWorkDay || workSchedule?.IsHoliday || workSchedule?.IsShutdown || workSchedule?.IsConsecutiveShutdown;
  }

  isPartialWorkDay(date: string): boolean {
    const workSchedule = this.getWorkScheduleForDate(date);
    return workSchedule?.IsWorkDay && !workSchedule?.IsFullDay && workSchedule?.RequestedMinutes > 0 && workSchedule?.ScheduledMinutes > 0 && (workSchedule?.RequestedMinutes < workSchedule?.ScheduledMinutes);
  }

  getWeeksBetweenDates(startDate: Date, endDate: Date): IHukAccordian[] {
    const weeks: IHukAccordian[] = [];
    let currentDate = new Date(startDate);
    let presentDate = new Date()
    let index = 0
    let weekNumber = 1
    let hourLabel: string;
    let minuteLabel: string
    let totalHour: string;
    let totalMin: string;
    let isLessThanCurrentdate: boolean
    while (currentDate <= endDate) {
      const dayOfWeek = currentDate.getDay()
      const workSchedule = this.getWorkScheduleForDate(this.datePipe.transform(currentDate, 'MM/dd/yyyy'));
      const scheduledMinutes = workSchedule?.ScheduledMinutes;
      const requestedMinutes = workSchedule?.RequestedMinutes;
      const owcsContent = this.storageManagementService.getSessionData(this.utilityConsts.IE_OWCS_CONTENT);
      if (owcsContent && owcsContent.data[INTAKE_OWCS_IDS.IE_079D_Body]) {
        hourLabel = owcsContent.data[INTAKE_OWCS_IDS.IE_079D_Body].value;
      }
      if (owcsContent && owcsContent.data[INTAKE_OWCS_IDS.IE_079E_Body]) {
        minuteLabel = owcsContent.data[INTAKE_OWCS_IDS.IE_079E_Body].value;
      }
      const totalWorkHoursMins = {
        hours: parseInt((scheduledMinutes / 60).toString(), 10),
        minutes: (scheduledMinutes % 60)
      };
      const hourValue = totalWorkHoursMins.hours
      if (totalWorkHoursMins?.hours < 10) {
        totalHour = (totalWorkHoursMins.hours === 1) ? (hourLabel.replace(hourLabel, '0' + hourValue + ' hr ')) : (hourLabel.replace(hourLabel, '0' + hourValue + ' hrs '));
      } else {
        totalHour = hourLabel.replace(hourLabel, hourValue + ' hrs ');
      }
      const minvalue = totalWorkHoursMins.minutes;
      if (totalWorkHoursMins?.minutes < 10) {
        totalMin = (totalWorkHoursMins.minutes === 1) ? (minuteLabel.replace(minuteLabel, '0' + minvalue + ' min')) : (minuteLabel.replace(minuteLabel, '0' + minvalue + ' mins'));
      } else {
        totalMin = minuteLabel.replace(minuteLabel, + minvalue + ' mins');
      }
      const WorkHoursMins = totalHour + totalMin;
      const weekIndex = weeks.findIndex(week => week.weekNumber === weekNumber);
      const formattedDate = this.datePipe.transform(currentDate, 'MM/dd/yyyy')
      const isNonWorkDay = this.isNonWorkDay(formattedDate)
      const isPartialWorkDay = this.isPartialWorkDay(formattedDate)
      const accordionState = false
      const accordionTotalHours = 0
      const showMandatoryQuesOnCheckBoxClick = false
      const selectedOption= null
      const showScheduledMandatoryOverTimeQues ={
        showMandatoryQues: false
      }
      const hourList =  [{name: '', value: ''}]
      const minuteList = [{name: '', value: ''}]
      const resetDropdown = false;
      const requestedTimeOff = false;
      const hours = 0
      const minutes = 0
      const showHourLabel = false;
      const showMinuteLabel = false;
      const accordianTotalHours = 0
      const accordionTotalMinutes = 0
      const showHoursAndMinutesLable = false
      const timeReportingLabel = false
      const accordionTotalHoursAndMinutes = ''
      const totalScheduledMinutes = 0
      const accordionTotalMissedMinutes = 0
      const accordionMissedHoursAndMinutes = ''
      const showTotalScheduledMins = false
      const scheduleMinutesArr = []
      const nonWorkdayHourList =  [{name: '', value: ''}]
      const nonWorkdayMinuteList = [{name: '', value: ''}]
      const resetNonWorkdayDropdown = false;
      const scheduledOTHours = 0;
      const scheduledOTMinutes = 0;
      const actualOTHours = 0;
      const requestedTimeMissedHour = 0;
      const requestedTimeMissedMinute = 0;
      const requestedTimeWorkedHour = 0;
      const requestedTimeWorkedMinute =0;
      const actualOTMins = 0;
      const accordionScheduledOTHours= 0;
      const accordionScheduledOTMinutes= 0;
      const accordionActualOTHours= 0;
      const accordionActualOTMins= 0;
      const accordionTotalWorkedHour= 0;
      const accordionTotalWorkedMinute= 0;
      const accordionTotalMissedHour= 0;
      const accordionTotalMissedMinute= 0;
       const nonWorkDayScheduledRadioOptions = [
        {
          name: 'Yes',
          value: '1001',
          checked: false
        },
        {
          name: 'No',
          value: '1002',
          checked: false
        }
      ];
      if (currentDate.getFullYear() < presentDate.getFullYear() ||
        (currentDate.getFullYear() === presentDate.getFullYear() && currentDate.getMonth() < presentDate.getMonth()) ||
        (currentDate.getFullYear() === presentDate.getFullYear() && currentDate.getMonth() === presentDate.getMonth() && currentDate.getDate() < presentDate.getDate())) {
        isLessThanCurrentdate = true
      } else {
        isLessThanCurrentdate = false
      }
      const dateFormat = this.getRotationDateFormat(currentDate.toDateString());
      if (weekIndex === -1) {
        weeks.push({ weekNumber, days: [{ date: new Date(currentDate), isNonWorkDay, isPartialWorkDay, dateFormat, weekNumber, isLessThanCurrentdate, scheduledMinutes, requestedMinutes, WorkHoursMins, index, showMandatoryQuesOnCheckBoxClick, selectedOption, showScheduledMandatoryOverTimeQues, nonWorkDayScheduledRadioOptions, hourList, minuteList, resetDropdown,requestedTimeOff, hours, minutes, showTotalScheduledMins, showHourLabel, showMinuteLabel, nonWorkdayHourList, nonWorkdayMinuteList, resetNonWorkdayDropdown, scheduledOTHours, scheduledOTMinutes, actualOTHours, actualOTMins, requestedTimeMissedHour, requestedTimeMissedMinute, requestedTimeWorkedHour, requestedTimeWorkedMinute }], accordionState, accordionTotalHours, accordionTotalMinutes, accordionScheduledOTHours, accordionScheduledOTMinutes, accordionActualOTHours, accordionActualOTMins, showHoursAndMinutesLable, timeReportingLabel, accordionTotalHoursAndMinutes, totalScheduledMinutes, accordionTotalMissedMinutes, accordionTotalWorkedHour, accordionTotalWorkedMinute, accordionTotalMissedHour, accordionTotalMissedMinute, accordionMissedHoursAndMinutes, scheduleMinutesArr })
      } else {
        weeks[weekIndex].days.push({ date: new Date(currentDate), isNonWorkDay, isPartialWorkDay, dateFormat, weekNumber, isLessThanCurrentdate, scheduledMinutes, requestedMinutes, WorkHoursMins, index, showMandatoryQuesOnCheckBoxClick, selectedOption, showScheduledMandatoryOverTimeQues, nonWorkDayScheduledRadioOptions, hourList, minuteList, resetDropdown, requestedTimeOff, hours, minutes, showTotalScheduledMins, showHourLabel, showMinuteLabel, nonWorkdayHourList, nonWorkdayMinuteList, resetNonWorkdayDropdown, scheduledOTHours, scheduledOTMinutes, actualOTHours, actualOTMins ,requestedTimeMissedHour, requestedTimeMissedMinute,requestedTimeWorkedHour, requestedTimeWorkedMinute})
      }
      if (dayOfWeek === 6) {
        weekNumber++
      }
      index++
      currentDate.setDate(currentDate.getDate() + 1)
    }
    return weeks;
  }

  /*
  * returns Saturday 08/12/2023 or Saturday, 08/12/2023
  * */
  getDayAndDateFromDate(date: Date, removeComma: boolean): string {
    let formattedDate = new Intl.DateTimeFormat('en-US', {weekday: 'long', year: 'numeric', month: '2-digit', day: '2-digit'}).format(date);
    if (removeComma) {
      return formattedDate?.replace(/, /g, ' ');
    }
    return formattedDate;
  }

  /**
   * calculates and returns differnce between two dates
   *
   */
  getDifferencebwtwoDates(d1, d2): number {
    var diff = Math.abs(d1 - d2);
    return diff / (1000 * 60 * 60 * 24);
  }

  setupHukRadioYesNoOptions(btnOneText: string, btnTwoText: string): IHukRadioYesNoOptions[] {
    return [
      { name: btnOneText, value: YES_NO_OBJECT.YES, checked: false },
      { name: btnTwoText, value: YES_NO_OBJECT.NO, checked: false }
    ];
  }

  setupHukRadioOptions(btnOneText: string, valOne: string, btnTwoText: string, valTwo: string, btnThreeText?: string, valThree?: string): IHukRadioYesNoOptions[] {
    const hukOption = [
      { name: btnOneText, value: valOne, checked: false },
      { name: btnTwoText, value: valTwo, checked: false },
    ];
    if (valThree !== undefined) {
      hukOption.push({ name: btnThreeText, value: valThree, checked: false });
      return hukOption;
    } else {
      return hukOption;
    }
  }

  /*
  * setup dropdown options for huk select
  * @param maxCount: max limit for dropdown
  * @param startIndex: starting index for dropdown, if no value is passed it is defaulted to 0
  *                    no need to pass this argument if you want it to start from 0
  * @return dropdown options array for huk-select
  * */
  setupDropdownOptions(maxCount: number, startIndex: number = 0): IHukDropDownOptions[] {
    let data: IHukDropDownOptions[] = [];
    for (let i = startIndex; i <= maxCount; i++) {
      const option: string = ('0' + i).slice(-2);
      data.push({name: option, value: option});
    }
    return data;
  }

  setupDropdownOptionsFlightCrew(maxCount: number): IHukDropDownOptions[] {
    let data: IHukDropDownOptions[] = [];
    for (let i = 1; i <= maxCount; i++) {
      const option: string = ('0' + i).slice(-2);
      data.push({name: option, value: option});
    }
    return data;
  }

  /*
  * update dropdown to start from 1 minute if selected hour value is 0 hours
  * update dropdown to start from 1 hour if selected minute value is 0 minutes
  * @param selectedValue: value selected from dropdown
  * @param maxLimit: max value for dropdown
  * @return dropdown options array for huk-select
  * */
  preventZeroHoursZeroMins(selectedValue: number, maxLimit: number): IHukDropDownOptions[] {
    if (selectedValue === 0) {
      return this.setupDropdownOptions(maxLimit, 1);
    } else {
      return this.setupDropdownOptions(maxLimit);
    }
  }

   /*
  * Method to remove multiple form controls from a FormGroup
  * @param formGroup: the FormGroup from which controls will be removed
  * @param controls: An array of control names (strings) to be removed from the FormGroup
  * */
  removeControls(formGroup: FormGroup, controls: string[]): void {
    controls.forEach(control=> {
      if(formGroup.contains(control)){
        formGroup.removeControl(control);
      }
    })
  }

  /*
  * reset form control to empty or required
  * @param formControl: the form control to reset and enable
  * */
  resetFormControl(formControl: AbstractControl, isDropdown: boolean): void {
    formControl.setValue(isDropdown ? 'null' : ''); //'null'  resets dropdown to Required
    formControl.reset();
    formControl.enable();
  }

  /*
  * Generate and return dynamic step label string for all flows based on the toggle status
  * eg. Step 1 of 6 / Step 2 of 3 / Step 2 of 5
  * @param step:                step label to be replaced with new label based on toggle status
  * @param page:                the page for which the label is to be generated eg. Contact Info, Work Schedule, Taking Leave Time, etc.
  * @param transitionToBonding: optional param for bonding flow, determine if the transition to bonding flow is applicable and generate corresponding label
  * @param extensionBonding:    optional param for bonding flow, determine if the extension  to bonding flow is applicable and generate corresponding label
  * */
  createProgressBarStepLabel(page: number, transitionToBonding?: boolean, extensionBonding?: boolean): string {
    const flightCrew = this.storageManagementService.getIntakeSessionData(this.utilityConsts.FLIGHT_CREW);
    this.getBaseStep();
    const checkLOA: boolean = this.storageManagementService.getIntakeSessionData(IntakeSessionConstants.LOA_INDICATOR);
    let maxSteps;
    let stepNumber: string;
    if (checkLOA) {
      maxSteps = flightCrew? this.stepNumbers.LOA_Active_WITH_FLIGHT_CREW_MATERNITY : this.stepNumbers.LOA_ACTIVE_MAX_STEP;
    }else{
      maxSteps = flightCrew ? this.stepNumbers.FLIGHT_CREW_MAX_STEP : this.stepNumbers.FULL_BONDING_MAX_STEP
    }
    let step = "";
    switch (page) {
      case this.stepperPages.CONTACT_INFO:
        step = this.stepReplacement(this.intakeConstants.STEP_ONE, maxSteps);
        break;

      case this.stepperPages.HEALTHCARE_PROVIDER_INFO:
        step = this.stepReplacement(this.intakeConstants.STEP_TWO, maxSteps);
        break;

      case this.stepperPages.ABOUT_YOUR_ABSENCE:
        stepNumber = this.getStepNumber(this.intakeConstants.STEP_THREE, this.intakeConstants.STEP_FOUR);
        step = this.stepReplacement(stepNumber, maxSteps);
        break;

      case this.stepperPages.ABOUT_YOUR_DELIVERY:
        stepNumber = this.getStepNumber(this.intakeConstants.STEP_FOUR, this.intakeConstants.STEP_FIVE);
        step = this.stepReplacement(stepNumber, maxSteps);
        break;

      case this.stepperPages.EMPLOYMENT_QUESTIONS:
        stepNumber = this.getStepNumber(this.intakeConstants.STEP_FIVE, this.intakeConstants.STEP_SIX);
        step = this.stepReplacement(stepNumber, maxSteps);
        break;

      case this.stepperPages.NOTIFICATION_PREFERENCES:
        stepNumber = this.getStepNumber(this.intakeConstants.STEP_SIX, this.intakeConstants.STEP_SEVEN);
        step = this.stepReplacement(stepNumber, maxSteps);
        break;

      case this.stepperPages.WORK_SCHEDULE:
        step = this.getWorkScheduleStep(transitionToBonding, extensionBonding);
        break;

      case this.stepperPages.TAKING_LEAVE:
        step = this.takingLeaveStepper(transitionToBonding, extensionBonding, flightCrew);
        break;

      case this.stepperPages.ABOUT_YOUR_BONDING:
        step = this.aboutYourBondingStepper();
        break;
      default: break;
    }
    return step;
  }

   /*
  * fetch the steps bases on given params
  * */
  getWorkScheduleStep(transitionToBonding?: boolean, extensionBonding?: boolean): string {
    let step = "";
    const flightCrew = this.storageManagementService.getIntakeSessionData(this.utilityConsts.FLIGHT_CREW);
    const checkLOA: boolean = this.storageManagementService.getIntakeSessionData(IntakeSessionConstants.LOA_INDICATOR);
    if (checkLOA && !flightCrew) {
      step = this.stepReplacement(this.intakeConstants.STEP_THREE, this.stepNumbers.LOA_ACTIVE_MAX_STEP);
    } else {
      step = this.workScheduleStepper(transitionToBonding, extensionBonding);
    }
    return step;
  }

  /*
  * fetch the steps number based on loa check
    * */
  getStepNumber(step: string, loaStep: string): string {
    const flightCrew = this.storageManagementService.getIntakeSessionData(this.utilityConsts.FLIGHT_CREW);
    const checkLOA: boolean = this.storageManagementService.getIntakeSessionData(IntakeSessionConstants.LOA_INDICATOR);
    if(checkLOA){
      if(flightCrew){
        return step;
      } else {
        return loaStep;
      }
    } else{
      return step;
    }
  }

  /*
  * fetch the base owcs text for all pages - 'Step [dynamic number] of [dynamic number]'
  * */
  getBaseStep(): string {
    if (!this.baseStep) {
      const owcsContent = this.storageManagementService.getSessionData(this.utilityConsts.NI_OWCS_CONTENT);
      if (owcsContent && owcsContent.data[INTAKE_OWCS_IDS.NI_873_Body]) {
        this.baseStep = owcsContent.data[INTAKE_OWCS_IDS.NI_873_Body].value;
      }
    }
    return this.baseStep;
  }

  /*
  * Generate dynamic label for Work Schedule Page based on toggle status
  * @param transitionToBonding: determine if the transition to bonding flow is applicable and generate corresponding label
  * @param extensionBonding:    determine if the extension to bonding flow is applicable and generate corresponding label
  * */
  workScheduleStepper(transitionToBonding: boolean, extensionBonding: boolean): string {
    if (transitionToBonding) {
      return this.stepReplacement('1', this.stepNumbers.TRANSITION_TO_BONDING_MAX_STEP);
    } else if (extensionBonding) {
      return this.stepReplacement('1', this.stepNumbers.EXTENSION_TO_BONDING_MAX_STEP);
    } else {
      return this.stepReplacement('2', this.stepNumbers.FULL_BONDING_MAX_STEP);
    }
  }

  /*
  * Generate dynamic label for Taking Leave Time Page based on toggle status
  * @param transitionToBonding: determine if the transition to bonding flow is applicable and generate corresponding label
  * @param extensionBonding:    determine if the extension to bonding flow is applicable and generate corresponding label
  * @param flightCrew:          determine if the flight crew flow is applicable and generate corresponding label
  * */
  takingLeaveStepper(transitionToBonding: boolean, extensionBonding: boolean, flightCrew: boolean): string {
    if (transitionToBonding && flightCrew) {
      return this.stepReplacement('1', this.stepNumbers.FLIGHT_CREW_TRANSITION_TO_BONDING_MAX_STEP);
    } else if (transitionToBonding) {
      return this.stepReplacement('2', this.stepNumbers.TRANSITION_TO_BONDING_MAX_STEP);
    } else if (flightCrew && extensionBonding) {
      return '';
    } else if (extensionBonding) {
      return this.stepReplacement('2', this.stepNumbers.EXTENSION_TO_BONDING_MAX_STEP);
    } else if (flightCrew) {
      return this.stepReplacement('2', this.stepNumbers.FLIGHT_CREW_MAX_STEP);
    } else {
      return this.stepReplacement('3', this.stepNumbers.FULL_BONDING_MAX_STEP);
    }
  }

  aboutYourBondingStepper() {
    return  this.stepReplacement('4', this.stepNumbers.FULL_BONDING_MAX_STEP);
  }

  /*
  * Generate dynamic label from base owcs string 'Step [dynamic number] of [dynamic number]'
  * replaces the string '[dynamic number]' with current and max steps applicable for current flow
  * @param curStep:    current step for current flow
  * @param maxSteps:   max steps for current flow
  * */
  stepReplacement(curStep: string, maxSteps: string): string {
    const dynamicStep = this.stepNumbers.DYNAMIC_STEP_NUMBER;
    return this.baseStep.replace(dynamicStep, curStep).replace(dynamicStep, maxSteps);
  }

  /**
   * Return the Radio button value based on selected or not
   *
   * @param selectedVal
   * @returns
   */
  getRadioButtonValue(selectedVal: CustomEvent|string): string {
    return (selectedVal instanceof CustomEvent)? this.getHukRadioButtonValues(selectedVal):selectedVal;
  }

  getHukRadioButtonValues(selectedVal: any): string {
    if (selectedVal?.detail?.length > 2) {
      return selectedVal?.detail[0]?.checked ? selectedVal?.detail[0]?.value : selectedVal?.detail[1]?.checked ? selectedVal?.detail[1]?.value : selectedVal?.detail[2]?.value;
    } else {
      return selectedVal?.detail[0]?.checked ? selectedVal?.detail[0]?.value : selectedVal?.detail[1]?.value;
    }
  }

  /**
   *This method will take list of option of radio button and the value, Updated the selection status based on given value.
   *
   *@param radioButtonOptions: retrun list with updated vaule
   *@param value: option
   * */
  resetRadioButtonValue(radioButtonOptions: IHukRadioYesNoOptions[], value: string): void {
    if (radioButtonOptions?.length > 0) {
      if (value === this.appConstants.RESET) {
        radioButtonOptions[0].checked = false;
        radioButtonOptions[1].checked = false;
        return;
      }
      if (value === YES_NO_OBJECT.YES) {
        radioButtonOptions[0].checked = true;
        radioButtonOptions[1].checked = false;
      } else {
        radioButtonOptions[0].checked = false;
        radioButtonOptions[1].checked = true;
      }
    }
  }
  resetHUKRadioButtonValue(radioButtonOptions: IHukRadioYesNoOptions[], value: string): void {
    radioButtonOptions[0].checked = (value === radioButtonOptions[0].value) ? true : false;
    radioButtonOptions[1].checked = (value === radioButtonOptions[1].value) ? true : false;
    if (radioButtonOptions.length > 2) {
      radioButtonOptions[2].checked = (value === radioButtonOptions[2].value) ? true : false;
    }
  }

  getCIIFeatureData(): any {
    let ciiFeatureData = this.storageManagementService.getSessionData(SessionConstants.CII_FEATURE_DATA_FOR_ACTIVE_PERSON_ID);
    if (!ciiFeatureData) {
      ciiFeatureData = this.storageManagementService.getSessionData(SessionConstants.CII_FEATURE_DATA);
    }
    return ciiFeatureData;
  }

  getPersonId(): string {
    let personId = JSON.parse(this.storageManagementService.retrieveSessionObject(SessionConstants.APP_DATA)).appData.activeUserId;
    if (!personId) {
      personId = this.storageManagementService.getAppData(SessionConstants.USER_DATA) ? this.storageManagementService.getAppData(SessionConstants.USER_DATA).personIds[0] : null;
    }
    return personId;
  }

  /**
   *This method checks if it's a valid date.
   *
   *@param dateStr: date in 'MM/DD/YYYY'
   * */
  isValidDate(dateStr: string): boolean {
    const dateArr = dateStr.split("/");
    const month = parseInt(dateArr[0], 10) - 1;
    const day = parseInt(dateArr[1], 10);
    const year = parseInt(dateArr[2], 10);
    var d = new Date(year, month, day);
    if (d.getFullYear() === year && d.getMonth() === month && d.getDate() === day) {
        return true;
    }
    return false;
  }

  buildUnitOptions(AMBody: string, PMBody: string ): IMinutesHoursListObject[] {
    let data: IMinutesHoursListObject[] = [];
    data.push(
      {
        name: AMBody,
        value: AMBody
      },
      {
        name: PMBody,
        value: PMBody
      }
    )
    return data;
  }

  setReducedSchPrefillFlag(): boolean {
    let isAllDaysEqual = false;
    const isLOAEnabled: boolean = this.storageManagementService.getIntakeSessionData(IntakeSessionConstants.LOA_INDICATOR);
    const intakeType = isLOAEnabled ? IntakeSessionConstants.INTAKE_TYPE_MATERNITY : BondingIntakeSessionConstants.INTAKE_TYPE_BONDING;
    const workScheduleSessionData = this.storageManagementService.getSessionData(SessionConstants.WORK_SCHEDULE_REGULAR);
    const weeklySchedule = workScheduleSessionData?.empWorkSchedulesList[0]?.employeeWorkSchedules;
    const workMinutes = [];
    weeklySchedule?.forEach(week => {
      week?.weekDetails?.forEach(days => {
        if (days.workMinutes > 0) {
          workMinutes.push(days.workMinutes);
        }
      })
    });

    isAllDaysEqual = workMinutes.every(v => v === workMinutes[0]);
    this.storageManagementService.setIntakeSessionData(BondingIntakeSessionConstants.REDUCED_SCHEDULE_PREFIL, isAllDaysEqual, intakeType);
    return isAllDaysEqual;
  }

  displayMandatedOTQuestionCheck(fdaDate: string, otApplicable: boolean, fdaIdentifiedAsNonWorkDay?: boolean): boolean {
    if (otApplicable) {
      const leaveData: ISelectedLeaveDetails = this.storageManagementService.getIntakeSessionData(BondingIntakeSessionConstants.SELECTED_LEAVE_DETAILS, BondingIntakeSessionConstants.INTAKE_TYPE_BONDING);
      const gapInWorkDay = this.storageManagementService.getIntakeSessionData(this.utilityConsts.GAP_IN_WORKDAY, this.utilityConsts.INTAKE_TYPE_BONDING);
      const workSchedule = this.getWorkScheduleForDate(fdaDate);
      const fdaUpdate = this.storageManagementService.getIntakeSessionData(BondingIntakeSessionConstants.FDA_UPDATE, BondingIntakeSessionConstants.INTAKE_TYPE_BONDING);

      // hide mandate ot question
      if (workSchedule?.RequestedMinutes > workSchedule?.ScheduledMinutes) {
        // default ot response in session
        if (
            ((fdaUpdate === true && gapInWorkDay === false) || (fdaUpdate === false && gapInWorkDay === true))
            || (workSchedule.IsWorkDay && (fdaUpdate === false && gapInWorkDay === false)
                && leaveData?.sameContinuity?.toLowerCase() === 'false'
            )
            || (
                leaveData?.sameContinuity?.toLowerCase() === 'false'
                || (
                  (leaveData?.sameContinuity?.toLowerCase() === 'true')
                  && (leaveData?.continuity?.toLowerCase() !== AppLeaveConstants.CONTINUOUS.toLowerCase())
                )
            )
        ) {
          this.storageManagementService.setIntakeSessionData(this.utilityConsts.DEFAULT_MANDATED_OT_NONWORKDAY_RESPONSE, true, this.utilityConsts.INTAKE_TYPE_BONDING);
        }
        return false;
      }

      // display mandate ot question
      if (
          ((this.isNonWorkDay(fdaDate) || fdaIdentifiedAsNonWorkDay)
            && (
                (fdaUpdate === false && gapInWorkDay === true) || (fdaUpdate === true && gapInWorkDay === false)
                || (
                    fdaUpdate === false && gapInWorkDay === false
                    && (
                      leaveData?.sameContinuity?.toLowerCase() === 'false'
                      || (
                        (leaveData?.sameContinuity?.toLowerCase() === 'true') && workSchedule?.RequestedMinutes === 0
                        && (leaveData?.continuity?.toLowerCase() === AppLeaveConstants?.INTERMITTENT.toLowerCase())
                      )
                    )
                )
            )
          )
      ) {
        return true;
      }
    }
  }
  setSessionReturnToWork(returnToWorkFullSchedule){
    const currentDate = this.datePipe.transform(new Date(), 'MM/dd/yyyy');
    if (new Date(returnToWorkFullSchedule) < new Date(currentDate)) {
      this.storageManagementService.setIntakeSessionData(BondingIntakeSessionConstants.RETURN_TO_WORK_PAST_INDICATOR, true);
    }else if (new Date(returnToWorkFullSchedule) >= new Date(currentDate)) {
      this.storageManagementService.setIntakeSessionData(BondingIntakeSessionConstants.RETURN_TO_WORK_PAST_INDICATOR, false);
    }
  }

  /*
  * Converts value of input form control from string to number
  * @param formControlName: form control to parse to int
  * */
  fetchParsedValue(formControlName: string, form: FormGroup): number {
    if (formControlName && form?.get(formControlName)?.value) {
      return parseInt(form.get(formControlName).value, 10);
    }
  }

  /*
  * Common logic to set up the Existing OT Section with appropriate content
  * */
  prepareExistingOvertimeSectionContent(
    owcsContentMap: Map<string, string>, weekNumber: number, dayNumber: number,
    fdaDate: string, sectionHeader: string | null, day: IHukAccordianDays | null
  ): IExistingOvertimeContent {
    let fdaSchedule: LoadWorkScheduleList;
    if (fdaDate && sectionHeader) {
      fdaSchedule = this.getWorkScheduleForDate(fdaDate);
    }

    const hoursInNum = (Math.trunc((sectionHeader ? fdaSchedule?.RequestedMinutes : day?.requestedMinutes) / 60));
    let hours = hoursInNum < 10 ? `0${hoursInNum.toString()}` : hoursInNum.toString();

    const minsInNum = (Math.trunc((sectionHeader ? fdaSchedule?.RequestedMinutes : day?.requestedMinutes) % 60));
    let mins = minsInNum < 10 ? `0${minsInNum.toString()}` : minsInNum.toString();

    let topHeader = owcsContentMap?.get(INTAKE_OWCS_IDS.IE_129_Body);
    topHeader = topHeader
      ?.replace(Intake_Constants.DYNAMIC_HOURS, hours === '01' ? `${hours} hour` : `${hours} hours`)
      ?.replace(Intake_Constants.DYNAMIC_MINUTES, mins === '01' ? `${mins} minute` : `${mins} minutes`);

    let scheduledHoursMinsDropdownContent =
      this.setupExistingOTDropdownContent(
        INTAKE_OWCS_IDS.IE_086_Body, INTAKE_OWCS_IDS.IE_087_Body, INTAKE_OWCS_IDS.IE_088_Body, true, 24, 59,
        `existingOTScheduledHours_Week_${weekNumber}_Day_${dayNumber}`, `existingOTScheduledMins_Week_${weekNumber}_Day_${dayNumber}`,
        DROPDOWN_TYPE.Scheduled,DROPDOWN_CONFIG_TYPE.Aligned, owcsContentMap
      );

    let actualHoursMinsDropdownContent =
      this.setupExistingOTDropdownContent(
        INTAKE_OWCS_IDS.IE_130_Body, INTAKE_OWCS_IDS.IE_087_Body, INTAKE_OWCS_IDS.IE_088_Body, false, 24, 59,
        `existingOTActualHours_Week_${weekNumber}_Day_${dayNumber}`, `existingOTActualMins_Week_${weekNumber}_Day_${dayNumber}`,
        DROPDOWN_TYPE.Actual,DROPDOWN_CONFIG_TYPE.Aligned, owcsContentMap
      );

    if (sectionHeader) {
      return {
        sectionHeader,
        topHeader,
        dropdownSectionContent: {scheduledHoursMinsDropdownContent, actualHoursMinsDropdownContent},
        valid: false
      };
    } else if (new Date(day.date).toLocaleDateString() === new Date(fdaDate).toLocaleDateString()) {
      return {
        topHeader,
        dropdownSectionContent: {scheduledHoursMinsDropdownContent, actualHoursMinsDropdownContent},
        valid: false
      };
    } else {
      return {
        topHeader,
        radioLabel: owcsContentMap?.get(INTAKE_OWCS_IDS.IE_150_Body),
        radioFormControl: `requestMoreTimeOffRadio_Week_${weekNumber}_Day_${dayNumber}`,
        radioOptions: this.setupHukRadioYesNoOptions(owcsContentMap?.get(INTAKE_OWCS_IDS.IE_012_Body), owcsContentMap?.get(INTAKE_OWCS_IDS.IE_013_Body)),
        dropdownSectionContent: {scheduledHoursMinsDropdownContent, actualHoursMinsDropdownContent},
        noOTRequiredMessage: owcsContentMap?.get(INTAKE_OWCS_IDS.IE_147_Body),
        valid: false
      };
    }

  }

  /*
  * Common logic to set up the content required for the common Hours Minutes dropdown component to display
  * */
  setupExistingOTDropdownContent(questionText: string, hoursLabel: string, minutesLabel: string, display: boolean,
                                 hours: number, mins: number, hoursFormControl: string, minsFormControl: string,
                                 type: DROPDOWN_TYPE,dropdownConfigType: DROPDOWN_CONFIG_TYPE, owcsContentMap: Map<string, string>
  ): IHoursMinutesDropdownSectionContent {
    return {
      questionText: owcsContentMap ? owcsContentMap?.get(questionText) : questionText,
      hoursLabel:   owcsContentMap ? owcsContentMap?.get(hoursLabel)   : hoursLabel,
      minutesLabel: owcsContentMap ? owcsContentMap?.get(minutesLabel) : minutesLabel,
      hoursFormControl,
      minsFormControl,
      hoursSelectOptions: this.setupDropdownOptions(hours),
      minsSectionOptions: this.setupDropdownOptions(mins),
      display,
      type,
      dropdownConfigType
    };
  }
   /*
  * Common logic for sorting requestEndDate on absenceDetails
  * */
  sortAbsenceDetailsList(orgArr: ClaimLeaveDetailList): ClaimLeaveDetailList {
    return Array.isArray(orgArr)?orgArr.reduce((acc,item)=>[...acc,
      {...item,absenceDetails:item?.absenceDetails?.sort((a,b)=>new Date(b.requestEndDate).getTime()-
        new Date(a.requestEndDate).getTime())}]
    ,[]):orgArr
  }

  calculateTotalHoursMins(hoursMintDetails: IHukHoursMinsObject|IHukHourMinsOnlyObject): number{
    const hours= parseInt(hoursMintDetails.hour,10)*60;
    const mins = parseInt(hoursMintDetails.minute,10);
    const totalTime = hours + mins;
    return totalTime;
  }
}
