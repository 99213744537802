export class AppLeaveConstants {
  /** This section is mainly for LEAVE related constants */
  /** constants added by manish for leaves 9/18/2019*/
  /** Leave status start */
  static LEAVE_CANCELLED = 'cancelled';
  static LEAVE_CLOSED = 'closed';
  static LEAVE_OPEN = 'open';
  /** Leave status end */
  /** Leave Sub Type */
  static CONTINUOUS = 'continuous';
  static CONTINUOUS_LABEL = 'Continuous';
  static INTERMITTENT = 'intermittent';
  static INTERMITTENT_LABEL = 'Intermittent';
  static REDUCED = 'reduced';
  static REDUCED_SCHEDULE_LABEL = 'Reduced Schedule';
  static REDUCE_SCHEDULE = 'reduced schedule';
  static REDUCE_SCHEDULE_SUBTYPE = 'reducedschedule';
  static REDUCED_SCHEDULE_CONTINUITY = 'ReducedSchedule';
  static OPEN_STATUS = 'open';
  static RES_CODE_002 = '002';
  static RES_CODE_999 = '999';
  static AS_MUCH_TIME_AS_POSSIBLE = 'As much time as possible';
  static UNKNOWN = 'Unknown';
  /** Leave Sub Type */

  /** Absence Request status */
  static REQ_APPROVED = 'approved';
  static REQ_PENDED = 'pended';
  static REQ_DENIED =  'denied';
  static REQ_CANCELLED = 'cancelled';
  static REQ_ADMIN_CANCELLED = 'administrative cancel';
  static REQ_INACTIVE =  'inactive';
  static REQ_CLOSED: 'closed';
  /** Absence Request status*/

  /** Absence Request type */
  static APPOINTMENT = 'appointment';
  static FLARE_UP = 'flare up';
  /** Absence Request type*/

  /** Leave Plan Type start */
  static FEDERAL = 'federal';
  static STATE = 'state';
  static COMPANY = 'company';
  static LOCAL = 'local';
  /** Leave Plan Type end */

  /** Leave Plan status start */
  static APPROVED = 'approved';
  static PENDED = 'pended';
  static DENIED =  'denied';
  static CANCELLED = 'cancelled';
  static ACCOMMODATED = 'accommodated';
  static ADMIN_CANCELLED = 'administrative cancel';
  static INACTIVE =  'inactive';
  static CLOSED: 'closed';
  static LEAVE_PLAN_PAGE = 'leavePlan';
  /** Leave Plan status end */

  /** Leave Plan status ID */
  static APPROVED_ID = '1';
  static PENDED_ID = '3';
  static DENIED_ID = '2';

  /** Leave Type */
  static STANDALONE = 'standalone';
  static CONCURRENT = 'concurrent';
  /** Leave Type */

  /** for freq and duration page*/
  static FREQUENCY = 'f';
  static DURATION = 't';

  /** START A NEW CLAIM AA RESPONSE CODE */
  static SUCCESS_CODE_001 = '001';
  static OPEN_DISABILITY_CODE_003 = '003';
  static NO_CALIMID_CODE_005 = '005';
  /** START A NEW CLAIM AA RESPONSE CODE */

  /** START A NEW CLAIM SPECIFIC QUESTION FLOW */
  static ONE = '1';
  static FID_1516 = '1516';

  /** OWCS page call */
  static LEAVE_PLANS = 'Leave Plans';

  /** ADA leave */
  static PERMANENT = 'Permanent';
  static TO_BE_DETERMINED = 'To be determined';
  static ADA = 'ADA';
  static AT_WORK = 'at work';
  static NOT_AT_WORK = 'not at work';
  static URL_ADA_UPDATE_ACCOMMODATION = '/leave-details/adaUpdateAccommodation';
  /** constants added by manish 9/18/2019*/

  /*request extension leave component an success comp*/
  static URL_REQUEST_EXTENSION_LEAVE = '/leave-details/requestExtensions';
  static SUCCESS_RAE_LEAVE_URL = '/leave-details/successRequestExtensions';
  static URL_TIME_OFF_SUMMARY = '/leave-details/timeOffSummary';
  static REQUEST_AN_EXTENSION_LEAVE_CONFIRM = 'THAA Leave Request an Extension Confirm';
  static REQUEST_AN_EXTENSION_LEAVE_CONFIRM_U = 'THAA_Leave_Request_an_Extension_Confirm';
  /**/
  /* leave RTW*/
  static URL_LEAVE_RTW = '/leave-details/leaveReturnToWork';
  static URL_CONFIRM_RTW = '/leave-details/successReturnToWork';
  static ERR_REASON_FIELD = 'Please let us know why you\'ll have a gap between your last day of leave and your first day back at work.';
  /**/

  static I_DONT_KNOW_LABEL = 'I don\'t know';
  static CLAIM_SUBMIT_NOT_ALLOW = 'claimSubmitNotAllow';
  static EXTENSION_FAILURE = 'extensionFailure';
}

export enum INTERMITTENT_MODAL_CATEGORY { NonWorkday, Unavailable, PartialDay }
